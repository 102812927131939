import React, { useContext, useEffect } from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle'
import withApollo from '../../../hooks/withApollo'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'
import Media from '../../../pages/Media'
import useAddUpdateScene from '../hooks/useAddUpdateScene'
import { setNode } from '../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'

const MediaWrapper = () => {
  const updateDocumentTitle = useDocumentTitle()
  const { handleMediaDeletionUpdates } = useAddUpdateScene()
  const { scenario } = useContext(ScenarioEditorContext)
  const reactFlow = useReactFlow()
  const { getNodes } = reactFlow

  const linkCallback = (nodeId, media) => {
    const node = getNodes().find((n) => n.id === nodeId)
    setNode(reactFlow, {
      ...node,
      data: {
        ...node.data,
        hasVideo: true,
        video: { ...media, play4kUrl: media.playUrl },
      },
    })
  }

  const unlinkCallback = (nodeId) => {
    const node = getNodes().find((n) => n.id === nodeId)
    setNode(reactFlow, {
      ...node,
      data: {
        ...node.data,
        hasVideo: false,
        video: null,
      },
    })
  }

  useEffect(() => {
    updateDocumentTitle()
  }, [])

  return (
    <div
      className="c-container c-container--vertical"
      style={{ minHeight: 'calc(100vh - 122px)' }}>
      <div className="c-workarea" style={{ position: 'relative' }}>
        <Media
          scenarioId={scenario.id}
          deleteCallback={handleMediaDeletionUpdates}
          linkCallback={linkCallback}
          unlinkCallback={unlinkCallback}
        />
      </div>
    </div>
  )
}

export default withApollo(MediaWrapper)
