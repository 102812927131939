import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import {
  addElementMutation,
  updateElementMutation,
} from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import { getConnectedNodes, setNode } from '../helpers/nodeHelper'
import { useReactFlow, useUpdateNodeInternals } from 'reactflow'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'
import useShowVideoEditor from './useShowVideoEditor'

const useAddUpdateElement = () => {
  const { refetch } = useContext(ScenarioEditorContext)
  const reactFlow = useReactFlow()
  const { getEdges, setEdges, deleteElements } = reactFlow
  const updateNodeInternals = useUpdateNodeInternals()
  const showVideoEditor = useShowVideoEditor()
  const [_updateElement] = useMutation(updateElementMutation, {
    onError: handleApolloError,
  })

  const addElement = (obj) => {
    return _addElement({
      ...obj,
      variables: {
        ...obj.variables,
        anchorX: obj.variables.anchorX ?? 0,
        anchorY: obj.variables.anchorY ?? 0,
      },
    })
  }

  const updateElement = (obj) => {
    return _updateElement({
      ...obj,
      variables: {
        ...obj.variables,
        showInLookDirection: obj.variables.showInLookDirection ?? false,
        label: obj.variables.label ?? '',
      },
    }).then(
      ({
        data: {
          updateElement: { element },
        },
      }) => {
        // updating existing
        const nodes = reactFlow.getNodes()
        const node = nodes.find((n) =>
          n.data.elements?.find((el) => el.id === element.id)
        )

        // if node is not found, it means that the node is not in the current view yet
        // this condition is useful in duplicate scenes
        if (!node) return element
        const oldElement = node.data.elements.find((el) => el.id === element.id)

        node.data.elements = [
          ...node.data.elements.filter((el) => el.id !== element.id),
          element,
        ]
        setNode(reactFlow, node)

        // updating existing edges
        if (element.randomizedLinkToIds.length) {
          setEdges(
            getEdges().map((e) => {
              if (element.randomizedLinkToIds.includes(e.target)) {
                return {
                  ...e,
                  data: {
                    ...e.data,
                    points: element.points,
                  },
                }
              }
              return e
            })
          )
        } else {
          const elementEdges = getEdges().filter((e) =>
            e.id.startsWith(element.id)
          )
          if (elementEdges) {
            if (element.linkToEnding)
              deleteElements({
                edges: elementEdges.map((e) => ({ id: e.id })),
              })
            else
              setTimeout(() => {
                setEdges(
                  getEdges().map((e) => {
                    if (elementEdges.find((ee) => ee.id === e.id)) {
                      return {
                        ...e,
                        data: {
                          ...e.data,
                          points: element.points,
                        },
                      }
                    }
                    return e
                  })
                )
              }, 300)
          }
        }

        updateNodeInternals(node.id)
        if (element.linkToId) updateNodeInternals(element.linkToId)

        // If a node disconnect from target node and connect to ending, the sources nodes connected to target node should be updated
        if (oldElement.linkToId && element.linkToEnding) {
          const connectedNodes = getConnectedNodes(
            reactFlow,
            oldElement.linkToId
          )
          connectedNodes.forEach((nodeId) => updateNodeInternals(nodeId))
        }

        // refetch()

        return element
      }
    )
  }

  const [_addElement] = useMutation(addElementMutation, {
    onError: handleApolloError,
    onCompleted: ({ addElement: { element } }) => {
      if (showVideoEditor && element.defaultAnchorX && !element.anchorX) {
        updateElement({
          variables: {
            ...element,
            anchorX: element.defaultAnchorX,
            anchorY: element.defaultAnchorY,
          },
        })
      }
    },
  })

  return { updateElement, addElement }
}

export default useAddUpdateElement
