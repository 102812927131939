import { gql } from '@apollo/client'

const getCurrentPlanQuery = gql`
  query currentPlan {
    currentPlan {
      name
      legacy
      features
    }
  }
`

const getAllPlansQuery = gql`
  query allPlans {
    plans {
      id
      legacy
      name
      priceEur
      priceUsd
      includedWorkspaces
      includedPublishedScenarios
      features
      clientCount
    }
  }
`

const addPlanMutation = gql`
  mutation addPlan(
    $name: String!
    $priceEur: Int!
    $priceUsd: Int!
    $includedWorkspaces: Int!
    $includedPublishedScenarios: Int!
    $features: [PlanFeature!]!
  ) {
    addPlan(
      name: $name
      priceEur: $priceEur
      priceUsd: $priceUsd
      includedWorkspaces: $includedWorkspaces
      includedPublishedScenarios: $includedPublishedScenarios
      features: $features
    ) {
      plan {
        id
        legacy
        name
        priceEur
        priceUsd
        includedWorkspaces
        includedPublishedScenarios
        features
        clientCount
      }
    }
  }
`

const updatePlanMutation = gql`
  mutation updatePlan(
    $id: ID!
    $name: String!
    $priceEur: Int
    $priceUsd: Int
    $includedWorkspaces: Int
    $includedPublishedScenarios: Int
    $features: [PlanFeature!]!
  ) {
    updatePlan(
      id: $id
      name: $name
      priceEur: $priceEur
      priceUsd: $priceUsd
      includedWorkspaces: $includedWorkspaces
      includedPublishedScenarios: $includedPublishedScenarios
      features: $features
    ) {
      plan {
        id
        legacy
        name
        priceEur
        priceUsd
        includedWorkspaces
        includedPublishedScenarios
        features
        clientCount
      }
    }
  }
`
const removePlanMutation = gql`
  mutation removePlan($id: ID!) {
    removePlan(id: $id) {
      id
    }
  }
`
export {
  getCurrentPlanQuery,
  getAllPlansQuery,
  addPlanMutation,
  updatePlanMutation,
  removePlanMutation,
}
