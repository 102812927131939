import React, { useContext, useState } from 'react'
import { addSuccessAlert } from '../../../../../utils/helpers/alerts'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { useMutation } from '@apollo/client'
import { updateScenarioSettingsMutation } from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'
import { default as S } from '../../../../../utils/lang/en.json'

const VoiceControlForm = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [updating, setUpdating] = useState()
  const { id, hideNadir } = scenario
  
  const finishUpdating = () => {
    setUpdating(false)
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${scenario.name}' were saved succesfully`,
    })
    refetch()
  }


  const [updateScenarioSettings] = useMutation(updateScenarioSettingsMutation, {
    onError: handleApolloError,
    onCompleted: finishUpdating,
  })

  const handleFormSubmit = (e) => {
    const { target: form } = e
    e.preventDefault()
    setUpdating(true)
    updateScenarioSettings({
      variables: {
        id,
        hideNadir,
        voiceEnabled: form.elements['voiceEnabled'].checked,
        locale: form.elements['locale'].value,
      },
    })
  }

  return (
    <form onSubmit={handleFormSubmit} className="row pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Voice control</h2>
        <p>
          Trainees can interact with this scenario by using their voice. By
          speaking, they can select answers and play the scenario without the
          use of controllers.
        </p>
      </div>
      <div className="xlarge-6 medium-8 small-12 columns mt-0">
        <div className="flex-container align-middle align-justify">
          <label className="mb-2">Enable voice control?</label>
          <div className="o-switch">
            <input
              id="voiceEnabled"
              type="checkbox"
              defaultChecked={scenario.voiceEnabled}
              className="o-switch__input"
              name="voiceEnabled"
            />
            <label htmlFor="voiceEnabled" className="o-switch__paddle"></label>
          </div>
        </div>

        <label>Language for voice recognition</label>
        <select name="locale" defaultValue={scenario.locale}>
          <option value="EN">English</option>
          <option value="NL">Dutch</option>
          <option value="ES">Spanish</option>
        </select>

        <button
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal mb-0 ${updating ? 'o-button--loading' : ''}`}
          disabled={updating}
          aria-hidden="true">
          Confirm
        </button>
      </div>
    </form>
  )
}
export default VoiceControlForm
