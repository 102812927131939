import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { addSuccessAlert } from '../../../../../utils/helpers/alerts'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { default as S } from '../../../../../utils/lang/en.json'
import RadioButton from '../../../../UI/Form/RadioButton'
import NumberInput from '../../../../UI/Form/NumberInput'
import useUpdateScoringSystem from './useUpdateScoringSystem'
import Stars from '../../../../UI/Stars/Stars'
import {
  getScorePeriods,
  sortEndingSettings,
} from '../../../helpers/scoreHelper'

const ScoringSettings = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const { id } = scenario
  const [updating, setUpdating] = useState()
  delete scenario.scoringSystem.__typename

  const [scoringSystem, setScoringSystem] = useState(scenario.scoringSystem)

  const updateScoringSystem = useUpdateScoringSystem()

  const finishUpdating = () => {
    setUpdating(false)
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${scenario.name}' were saved succesfully`,
    })
    refetch()
  }

  const scoringIsDisabled = () =>
    !scoringSystem.kind || scoringSystem.kind === 'DISABLED'

  const handleFormSubmit = (e) => {
    const { target: form } = e
    e.preventDefault()
    setUpdating(true)
    updateScoringSystem({
      onCompleted: finishUpdating,
      variables: {
        id,
        scoringSystem: {
          ...scoringSystem,
          ...(scoringIsDisabled()
            ? { noStarDescription: form.elements['noStarDescription'].value }
            : {
                oneStarDescription: form.elements['description[1]'].value,
                twoStarDescription: form.elements['description[2]'].value,
                threeStarDescription: form.elements['description[3]'].value,
                fourStarDescription: form.elements['description[4]'].value,
                fiveStarDescription: form.elements['description[5]'].value,
              }),
        },
      },
    })
  }

  const renderStarsInputs = () => {
    if (scoringIsDisabled()) return null
    return (
      <>
        <label className="mt-2">
          How many points are required to {scoringSystem.kind.toLowerCase()} 1
          star?
        </label>
        <div className="flex-container">
          <NumberInput
            value={scoringSystem.pointsPerStar}
            min={0}
            step={1}
            changeHandler={(v) => {
              setScoringSystem({
                ...scoringSystem,
                pointsPerStar: v,
              })
            }}
          />
        </div>

        <label className="mt-2">
          How many stars are needed to complete this scenario?
        </label>
        <div className="flex-container mb-2">
          <NumberInput
            value={scoringSystem.starsForCompletion}
            min={0}
            step={1}
            max={5}
            changeHandler={(v) => {
              setScoringSystem({
                ...scoringSystem,
                starsForCompletion: v,
              })
            }}
          />
        </div>
      </>
    )
  }
  const renderFeedbacks = useCallback(() => {
    if (scoringIsDisabled())
      return (
        <>
          <label className="flex-container align-middle mt-2">
            <span className="ml-0-5">Feedback</span>
            <span className="text-stable-dark text-normal">
              &nbsp;•&nbsp;No points
            </span>
          </label>
          <textarea
            rows={5}
            defaultValue={scoringSystem.noStarDescription}
            name="noStarDescription"></textarea>
        </>
      )

    const endingSettings = sortEndingSettings(scoringSystem.endingSettings)
    const scoresPeriods = getScorePeriods(
      scoringSystem,
      endingSettings,
      scoringSystem.pointsPerStar
    )

    const renderMessage = (index) => {
      let str = `When a trainee scores`
      if (index === 0) {
        str = `${str} ${scoresPeriods[index]} points`
        if (scoringSystem.kind === 'SUBTRACT') return str
        return `${str} or more`
      }
      if (index === scoresPeriods.length - 1)
        return `${str} ${scoringSystem.kind === 'SUBTRACT' ? 'more than' : 'less than'} 
                ${scoresPeriods[index - 1]} points`

      str = `${str} ${scoresPeriods[index]} points`
      return `${str} ${scoringSystem.kind === 'SUBTRACT' ? 'or less' : 'or more'}, 
              but ${scoringSystem.kind === 'SUBTRACT' ? 'more than' : 'less than'} 
              ${scoresPeriods[index - 1]} points`
    }

    return endingSettings.map((endingSetting, index) => (
      <Fragment key={endingSetting.stars}>
        <label className="flex-container align-middle">
          <Stars value={endingSetting.stars} />
          <span className="ml-0-5">
            Feedback for {endingSetting.stars} stars
          </span>
          {/* <span className="text-stable-dark text-normal">
            &nbsp;•&nbsp;Equals {endingSetting.points} points
          </span> */}
        </label>
        {scoringSystem.pointsPerStar > 0 && (
          <p className="mb-1">{renderMessage(index)}</p>
        )}
        <textarea
          rows={5}
          defaultValue={endingSetting.description}
          name={`description[${endingSetting.stars}]`}></textarea>
      </Fragment>
    ))
  }, [scoringSystem])

  useEffect(() => {
    setScoringSystem(scenario.scoringSystem)
  }, [scenario.scoringSystem])

  return (
    <form onSubmit={handleFormSubmit} className="row pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Scoring settings</h2>
        <aside>
          <p className="mb-2">
            Determine the scoring system you would like to use. Use subtract
            points when trainees need to collect as few points as possible. Use
            add points when trainees need to collect as many points as possible.
            <br />
            Decide how many points are required to subtract or add stars to
            calculate the final score. For each score there is specific
            feedback.
          </p>
        </aside>
      </div>
      <div className="xlarge-6 medium-8 small-12 columns mt-0">
        <label>Scoring system</label>
        <div>
          <RadioButton
            id="subtract-kind"
            label={
              <>
                Subtract points
                <span className="text-stable-dark">
                  {' '}
                  • Start with 5 stars and try to collect as few points as
                  possible
                </span>
              </>
            }
            checked={scoringSystem.kind === 'SUBTRACT'}
            name="kind"
            labelClassName="ml-0"
            clickHandler={() => {
              setScoringSystem({
                ...scoringSystem,
                kind: 'SUBTRACT',
              })
            }}
            value="SUBTRACT"
          />
        </div>
        <div>
          <RadioButton
            id="add-kind"
            label={
              <>
                Add points
                <span className="text-stable-dark">
                  {' '}
                  • Start with 1 star and try to collect as many points as possible
                </span>
              </>
            }
            checked={scoringSystem.kind === 'ADD'}
            name="kind"
            labelClassName="ml-0"
            clickHandler={() => {
              setScoringSystem({
                ...scoringSystem,
                kind: 'ADD',
              })
            }}
            value="ADD"
          />
        </div>
        <div>
          <RadioButton
            id="no-kind"
            label={
              <>
                No points
                <span className="text-stable-dark">
                  {' '}
                  • No points are calculated
                </span>
              </>
            }
            checked={scoringIsDisabled()}
            name="kind"
            labelClassName="ml-0"
            clickHandler={() => {
              setScoringSystem({
                ...scoringSystem,
                kind: 'DISABLED',
              })
            }}
            value="DISABLED"
          />
        </div>

        {renderStarsInputs()}
        {renderFeedbacks()}

        <button
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal mb-0 ${updating ? 'o-button--loading' : ''}`}
          disabled={updating}
          aria-hidden="true">
          Confirm
        </button>
      </div>
    </form>
  )
}
export default ScoringSettings
