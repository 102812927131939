import React from 'react'
import Modal from '../../../UI/Modal'

const ChangeFilmingGroupModal = ({ appendTo, onConfirm }) => {
  return (
    <Modal
      id="change-filming-group-modal"
      appendTo={appendTo}
      headerText={`Are you sure you want to change sorting?`}
      subtitle="Grouping scenes can change the order of scenes in the filming schedule."
      useDefaultButtons
      textAssertive
      submitButtonText="Confirm"
      submitHandler={onConfirm}
    />
  )
}
export default ChangeFilmingGroupModal
