import React, { useEffect, useRef } from 'react'
import { Handle, Position, useEdges, useReactFlow } from 'reactflow'
import {
  useRemoveNewNodePlaceholder,
  selectNodes,
} from '../../../helpers/nodeHelper'
import useAddUpdateScene from '../../../hooks/useAddUpdateScene'
import { newEdgeId } from '../../../helpers/edgeHelper'

const NewNode = ({ xPos, yPos, data }) => {
  const removeNewNodePlaceholder = useRemoveNewNodePlaceholder()
  const reactFlow = useReactFlow()
  const edges = useEdges()
  const inputRef = useRef()

  const { addScene } = useAddUpdateScene()

  const connectedEdge = edges.find((e) => e.id === newEdgeId)

  const handleEnter = (e) => {
    addScene({
      name: inputRef.current.value,
      scenarioId: data.scenarioId,
      canvasX: xPos,
      canvasY: yPos,
    }).then((scene) => {
      removeNewNodePlaceholder()

      setTimeout(() => {
        selectNodes(reactFlow, [scene.id])
      }, 200)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 0)

    const handleKeydown = (event) => {
      if (event.key === 'Escape') removeNewNodePlaceholder()
      if (event.key === 'Enter') handleEnter()
    }
    window.addEventListener('keydown', handleKeydown)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <div className="o-scene o-scene--new">
      <input
        type="text"
        placeholder="Give scene a title or leave empty"
        ref={inputRef}
        className="tracking-tighter"
      />
      {connectedEdge && (
        <Handle
          type="target"
          position={Position.Left}
          id={`e${data.id}`}
          className="o-scene__handle"
        />
      )}
    </div>
  )
}

export default NewNode
