import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import { addErrorAlert, addSuccessAlert } from '../../../utils/helpers/alerts'
import { exportActivityQuery } from '../../../apollo/query/activities'

const ExportModal = ({ id, appendTo }) => {
  const [exportActivity] = useMutation(exportActivityQuery, {
    onCompleted: (data) => {
      if (data?.exportActivity.success)
        addSuccessAlert({ title: 'Export is in progress' })
      else addErrorAlert({ title: 'Something went wrong' })
    },
  })

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="Export activity log"
      submitHandler={exportActivity}
      useDefaultButtons>
      <div className="text-stable-dark pt-3 pb-3 text-normal">
        Exports are made in the background. You are notified by email when
        finished.
      </div>
    </Modal>
  )
}
export default ExportModal
