import { useEffect, useRef, useContext } from 'react'
import useAddUpdateScene from './useAddUpdateScene'
import useAddUpdateElement from './useAddUpdateElement'
import { useNodesInitialized, useReactFlow } from 'reactflow'
import { scenesToEdges } from '../helpers/edgeHelper'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'

const useDuplicateNodes = () => {
  const reactFlow = useReactFlow()
  const { addScene } = useAddUpdateScene()
  const { addElement, updateElement } = useAddUpdateElement()
  const { setNodes, addEdges } = reactFlow
  const {
    scenario: { id: scenarioId },
  } = useContext(ScenarioEditorContext)
  const newScenesRef = useRef([])

  const duplicateNodes = async (nodes) => {
    const scenes = nodes.map((node) => ({
      name:
        node.data.name === `Scene ${node.data.number}` ? '' : node.data.name,
      start: false,
      canvasX: node.position.x + 100,
      canvasY: node.position.y + 100,
      scenarioId,
      selected: false,
    }))
    const responses = []
    for (let index = 0; index < scenes.length; index++) {
      responses.push(await addScene(scenes[index]))
    }
    newScenesRef.current = responses.map((scene, index) => ({
      ...scene,
      oldElements: nodes[index].data.elements,
      elements: [],
      oldSceneId: nodes[index].id,
    }))
  }

  const nodesInitialized = useNodesInitialized()

  useEffect(() => {
    async function duplicateElements() {
      if (nodesInitialized && newScenesRef.current?.length) {
        const newScenes = newScenesRef.current
        const nodesToBeUpdated = []
        const nodes = reactFlow.getNodes()
        const scenesToBeUpdated = newScenes.filter((node) =>
          nodes.find((n) => n.id === node.id)
        )
        if (scenesToBeUpdated.length) {
          await Promise.all(
            scenesToBeUpdated.map(async (scene, index) => {
              const node = nodes.find((n) => n.id === scene.id)
              const elements = await Promise.all(
                scene.oldElements.map(async (oldElement) => {
                  return await addElement({
                    variables: {
                      ...oldElement,
                      sceneId: scene.id,
                    },
                  }).then(
                    ({
                      data: {
                        addElement: { element },
                      },
                    }) => {
                      const linkToId = newScenes.find(
                        (s) => s.oldSceneId === oldElement.linkToId
                      )?.id
                      const newElement = {
                        ...oldElement,
                        id: element.id,
                        linkToId,
                      }
                      updateElement({
                        variables: newElement,
                      })
                      return newElement
                    }
                  )
                })
              )
              nodesToBeUpdated.push({
                ...node,
                data: {
                  ...node.data,
                  elements,
                },
              })
              scenesToBeUpdated[index].elements = elements
            })
          )
          newScenesRef.current = null
          setNodes(
            nodes.map((n) => nodesToBeUpdated.find((nu) => nu.id === n.id) ?? n)
          )
          const newEdges = scenesToEdges(scenesToBeUpdated)
          addEdges(newEdges)
        }
      }
    }

    duplicateElements()
  }, [nodesInitialized, newScenesRef.current])

  return duplicateNodes
}

export default useDuplicateNodes
