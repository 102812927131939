/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { FaEllipsisH, FaUsers, FaSearch } from 'react-icons/fa'
import { default as S } from '../utils/lang/en.json'
import { getUsersQuery } from '../apollo/query/user'
import useCurrentUser from '../hooks/useCurrentUser'
import { getGroupNamesQuery } from '../apollo/query/groups'
import Table from '../components/Table'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import TableHeader from '../components/UI/TableHeader'
import withApollo from '../hooks/withApollo'
import {
  dateFormat,
  sortOnKey,
  dateFormatWithoutTZConversion,
} from '../utils/format'
import Delay from '../utils/helpers/Delay'
import Toast from '../components/UI/Notifications/Toast'
import useFoundation from '../hooks/useFoundation'
import Dropdown from '../components/UI/Menu/Dropdown'
import TraineeUserRole from '../components/Trainees/Table/TraineeUserRole'
import TraineeUserName from '../components/Trainees/Table/TraineeUserName'
import TraineeUserGroup from '../components/Trainees/Table/TraineeUserGroup'
import TraineeOptionDropdown from '../components/Trainees/TraineeOptionDropdown'
import SelectMore from '../components/Trainees/SelectMore'
import ButtonBar from '../components/Trainees/Table/ButtonBar'
import SearchBar from '../components/Trainees/Table/SearchBar'
import TraineeModals from '../components/Trainees/TraineeModals'
import { truncate } from '../utils/helpers/javascript'
import usePreferences from '../hooks/usePreferences'
import { filterColumnsHandler, sortHandler } from '../components/tableFunctions'

import DataTooltip from '../components/UI/DataTooltip'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import Input from '../components/UI/Form/Input'

const Trainees = () => {
  // State management
  const ref = useFoundation()
  const dropdownButtonRef = useFoundation()
  const [preferences, setPreferences] = usePreferences('trainees')
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [dismissToast, setDismissToast] = useState(false)
  const [bulkActionAddUsers, setBulkActionAddUsers] = useState(true)

  // Search and filter states
  const [currentTrainee, setCurrentTrainee] = useState({})
  const [selectedTrainees, setSelectedTrainees] = useState([])

  const [filterOption, setFilterOption] = useState({})

  // Filter groups, roles, owners, and users
  const [ownerIds, setOwnerIds] = useState([])
  const [filteredUsers, setFilteredUsers] = useState({})
  const [searchQuery, setSearchQuery] = useState('')

  // Data & render states
  const numberOfItems = 100
  const [limit, setLimit] = useState(2 * numberOfItems)

  const [showLoadingIcon, setShowLoadingIcon] = useState(false)
  const [lmsData, setLmsData] = useState([])

  const [currentUser, currentRole] = useCurrentUser()

  const {
    loading: loadingGroups,
    data: groupsData,
    refetch: refetchGroupData,
  } = useQuery(getGroupNamesQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      const lms = d.groups.filter(({ automated }) => automated)
      setLmsData(lms)
    },
  })

  const {
    data,
    error,
    refetch: refetchUsers,
  } = useQuery(getUsersQuery, {
    onCompleted: (d) => {
      const o = d.users.reduce((a, user) => {
        if (user.role === 'OWNER') {
          a.push(user.id)
        }
        return a
      }, [])
      setOwnerIds(o)
    },
  })

  if (!preferences || !currentUser || !data) {
    return (
      <Delay>
        <LoadingSpinner text="Loading trainees..." />
      </Delay>
    )
  }

  if (loadingGroups || !groupsData || !currentRole) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const applyFilterToUsers = (users) => {
    return users.reduce((f, user) => {
      const { id, name, email, employeeNumber, externalId } = user
      const { group, lms, role } = filteredUsers
      const search =
        `"${user.id} ${name} ${email} ${employeeNumber} ${externalId}"`.toLowerCase()

      const isInGroup = !group || group.includes(id)
      const isInLms = !lms || lms.includes(id)
      const hasCorrectRole = !role || role.includes(id)

      const matchesSearchQuery =
        !searchQuery || search.includes(searchQuery.toLowerCase())

      if (isInGroup && isInLms && hasCorrectRole && matchesSearchQuery)
        f.push(user)
      return f
    }, [])
  }

  const createLmsContent = (lms) => {
    if (!lms.length) return '-'
    if (lms.length === 1) return lms[0]
    const otherLmsList = [...lms].slice(1)
    const listItem = (l) => `<li>${l}</li>`
    const title = `
    <ul style="margin-bottom: 0px;text-align:left;">
    ${otherLmsList.map((l) => listItem(l)).join('')}
    </ul>
    `
    return (
      <span>
        {lms[0]}
        <DataTooltip title={title}>
          <label
            className="ml-1 o-label--custom o-label--stable-lighter
            text-small text-bold text-stable-dark">
            + {lms.length - 1}
          </label>
        </DataTooltip>
      </span>
    )
  }

  const sortedData = () => {
    // Add groups to user objects
    if (!preferences || !data.users) return null

    const { sortOn } = preferences

    // apply filters
    const filtered = applyFilterToUsers(data.users)
    // for filtered items, add group information
    const rows = filtered.reduce((array, user) => {
      const lms = lmsData.reduce((a, group) => {
        if (group.userIds.includes(Number(user.id))) {
          a.push(group.name)
        }
        return a
      }, [])
      const groupNames = groupsData?.groups.reduce((b, group) => {
        if (group.automated) return b
        if (group.userIds.includes(Number(user.id))) {
          b.push(group.name)
        }
        return b
      }, [])

      array.push({
        ...user,
        groupNames,
        lms: createLmsContent(lms),
        groupCount: groupNames?.length,
      })
      return array
    }, [])

    const parsedData = sortOnKey(rows, sortOn.value, sortOn.isAscending)
      .slice(0, limit)
      .reduce((array, user) => {
        array.push({
          id: user.id,
          name: {
            value: user.name || user.anonymous ? 'Anonymous' : 'Unknown',
            render: (
              <TraineeUserName
                name={user.name}
                isAnonymous={user.anonymous}
                isCurrentUser={currentUser?.id === user.id}
              />
            ),
          },
          role: {
            value: user.role,
            render: <TraineeUserRole role={user.role} />,
          },
          email: {
            value: user.email,
            render: (
              <span title={user.email}>{truncate(user.email, 40, true)}</span>
            ),
          },
          employeeNumber: user.employeeNumber || user.externalId || '-',
          groupCount: {
            value: user.groupCount,
            render: (
              <TraineeUserGroup
                count={user.groupCount}
                names={user.groupNames}
              />
            ),
          },
          lms: user.lms,
          attemptCount: {
            value: user.attemptCount,
            render: user.attemptCount
              ? `${user.attemptCount} attempt${
                  user.attemptCount === 1 ? '' : 's'
                }`
              : '-',
          },
          lastAttemptAt: user.lastAttemptAt
            ? dateFormatWithoutTZConversion(user.lastAttemptAt)
            : '-',
          createdAt: dateFormat(user.createdAt),
        })
        return array
      }, [])

    if (!parsedData.length && !showEmptyState) setShowEmptyState(true)
    if (parsedData.length && showEmptyState) setShowEmptyState(false)

    if (showLoadingIcon) {
      setShowLoadingIcon(false)
    }
    return parsedData
  }

  const tableHeaders = [
    'name',
    'role',
    'email',
    'employeeNumber',
    'groupCount',
    'lms',
    'attemptCount',
    'lastAttemptAt',
    'createdAt',
  ]

  const checkboxHandler = (id) => {
    let newSelection = [...selectedTrainees]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    return setSelectedTrainees(() => newSelection)
  }

  const clearFilterHandler = () => {
    setSearchQuery('')
    setSelectedTrainees([])
    setFilterOption({ role: '', group: '', lms: '' })
    setFilteredUsers({})
    setShowEmptyState(false)
  }

  const clearHandler = () => {
    setCurrentTrainee({})
    setSelectedTrainees([])
  }

  // Dropdown for rowSuffix
  const optionsDropdownHandler = (id) => {
    const user = data.users.find((u) => u.id === id)

    const editHandler = () => {
      const isInGroups = groupsData.groups.reduce((a, group) => {
        if (group.userIds.includes(Number(user.id))) {
          a.push(group)
        }
        return a
      }, [])
      setCurrentTrainee({ ...user, groups: isInGroups })
      $('#edit-trainee-modal').foundation('open')
    }
    const deleteHandler = () => {
      setCurrentTrainee(user)
      $('#delete-trainee-modal').foundation('open')
    }
    return (
      <TraineeOptionDropdown
        id={id}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        setCurrentTrainee={() => setCurrentTrainee(user)}
        isTrainee={user?.role === 'USER'}
        isOwner={user?.role === 'OWNER'}
        isAnonymous={user?.anonymous}
        isRestricted={user?.restricted}
      />
    )
  }
  const tableSearchBar = () => {
    const getUserRoleData = () =>
      data.users.reduce(
        (a, { role, id }) => ({
          ...a,
          [role]: {
            userIds: [...a[role].userIds, id],
          },
        }),
        {
          USER: { userIds: [] },
          MANAGER: { userIds: [] },
          WRITER: { userIds: [] },
          INSTRUCTOR: { userIds: [] },
          PUBLISHER: { userIds: [] },
          OWNER: { userIds: [] },
        }
      )
    const filterUserIds = (filter, type, toFilterData, useKey = false) => {
      if (!filter) return setFilteredUsers({ ...filteredUsers, [type]: null })
      let userIds
      if (useKey) {
        userIds = toFilterData[filter].userIds
      } else {
        userIds = toFilterData.find(({ name }) => name === filter).userIds
      }
      return setFilteredUsers({ ...filteredUsers, [type]: userIds.map(String) })
    }
    const filterData = {
      lms: lmsData,
      group: groupsData.groups.filter(({ automated }) => !automated),
      role: getUserRoleData(),
    }

    const filterHandler = (filter, type) => {
      setFilterOption({ ...filterOption, [type]: filter })
      filterUserIds(filter, type, filterData[type], type === 'role')
      if (showEmptyState) setShowEmptyState(false)
    }
    return (
      <SearchBar
        filterOption={filterOption}
        filterData={filterData}
        filterHandler={filterHandler}
      />
    )
  }

  const tableButtonBar = (
    <ButtonBar
      headers={tableHeaders}
      headersValues={S.table.trainees.headers}
      filterHandler={(value) =>
        filterColumnsHandler(value, preferences, setPreferences)
      }
      columns={preferences.filteredColumns}
    />
  )

  const showTablePrefix = () => {
    const userIds = applyFilterToUsers(data.users).map((user) => user.id)
    return (
      <SelectMore
        visibleIds={sortedData().map(({ id }) => id)}
        allIds={userIds}
        selectedTrainees={selectedTrainees}
        selectMoreHandler={(ids) => setSelectedTrainees(ids)}
        currentFilterUserCount={userIds.length}
      />
    )
  }

  const dismissToastHandler = () => {
    setDismissToast(true)
    setTimeout(() => {
      setDismissToast(false)
    }, 300)
    setTimeout(() => {
      setSelectedTrainees([])
    }, 10)
  }

  const toastContent = (
    <Dropdown
      id="dropup-toast"
      position="top"
      alignment="right"
      offset={{ left: '17px' }}
      button={
        <button
          ref={dropdownButtonRef}
          data-toggle="dropup-toast"
          className="cursor-pointer h-100 outline-none">
          <FaEllipsisH />
        </button>
      }>
      <>
        <li
          className="o-dropdown__list-item"
          onClick={() => setBulkActionAddUsers(true)}
          data-open="bulk-manage-trainee-groups-modal">
          Add trainees to group
        </li>
        <li
          className="o-dropdown__list-item"
          onClick={() => setBulkActionAddUsers(false)}
          data-open="bulk-manage-trainee-groups-modal">
          Remove trainees from group
        </li>
        <li className="o-dropdown__list-item" data-open="export-trainees-modal">
          Export trainees
        </li>
        <hr className="mb-1 mt-1"></hr>
        <li className="o-dropdown__list-item" data-open="delete-trainee-modal">
          Delete trainees
        </li>
      </>
    </Dropdown>
  )

  const traineeModalContent = () => (
    <TraineeModals
      appendTo="#trainees"
      ownerIds={ownerIds}
      selectedTrainees={selectedTrainees}
      lmsUserIds={[...new Set(lmsData.map((group) => group.userIds).flat())]}
      currentTrainee={currentTrainee}
      groups={groupsData.groups}
      importHandler={() => {
        refetchGroupData()
        refetchUsers()
      }}
      addTraineeCallBack={() => {
        refetchUsers()
        refetchGroupData()
        sortedData()
      }}
      bulkActionAddUsers={bulkActionAddUsers}
      bulkActionCallback={() => {
        refetchGroupData()
        sortedData()
        setSelectedTrainees([])
      }}
      clearHandler={clearHandler}
      editCallbackHandler={() => {
        refetchGroupData()
        sortedData()
        setCurrentTrainee({})
      }}
    />
  )
  const pageContent = () => {
    if (showEmptyState) {
      return (
        <FilterEmptyState
          type="trainees"
          icon={<FaUsers />}
          clickHandler={clearFilterHandler}
        />
      )
    }
    return (
      <Table
        data={sortedData()}
        headers={tableHeaders.filter(
          (i) => !preferences.filteredColumns.includes(i)
        )}
        sortOn={preferences.sortOn}
        selectable
        loadMore={applyFilterToUsers(data.users).length > limit}
        loadMoreHandler={() => setLimit(limit + numberOfItems)}
        selectedItems={selectedTrainees}
        checkboxHandler={checkboxHandler}
        selectAllHandler={setSelectedTrainees}
        sortHandler={(value) => sortHandler(value, preferences, setPreferences)}
        type="trainees"
        placeholderRows={35}
        rowSuffix={optionsDropdownHandler}
        tablePrefix={showTablePrefix()}
      />
    )
  }

  return (
    <div id="trainees" ref={ref}>
      {traineeModalContent()}
      <div className="grid-container fluid">
        <TableHeader
          title="Trainee"
          length={applyFilterToUsers(data.users).length}
          leftBar={
            <>
              {tableSearchBar()}
              <span className="cell auto large-shrink">
                <Input
                  styleComponent
                  collapsable
                  prefix={<FaSearch />}
                  defaultValue={searchQuery}
                  changeHandler={(value) => {
                    if (showEmptyState) setShowEmptyState(false)
                    setSearchQuery(value)
                    setLimit(3 * numberOfItems)
                  }}
                />
              </span>
            </>
          }
          rightBar={tableButtonBar}
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        {pageContent()}
      </div>
      <Toast
        dismissable
        id="toast-id"
        isClosing={dismissToast}
        count={selectedTrainees.length}
        itemTypeText={selectedTrainees.length > 1 ? 'trainees' : 'trainee'}
        closeHandler={dismissToastHandler}>
        {toastContent}
      </Toast>
    </div>
  )
}

export default withApollo(Trainees)
