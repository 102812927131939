import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import Modal from '../../../../UI/Modal'
import { validateImageExtension } from '../../../../../utils/validation/validate'
import { addErrorAlert } from '../../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../../utils/errors'
import { useMutation, useQuery } from '@apollo/client'
import {
  publishScenarioMutation,
  updateScenarioImageMutation,
  updateScenarioMutation,
} from '../../../../../apollo/query/scenarios'
import { DirectUpload } from '@rails/activestorage'
import PublishGroupsList from './PublishGroupsList'
import { getCoursesQuery } from '../../../../../apollo/query/courses'
import confetti from 'canvas-confetti'

const MAX_HEIGHT = 73
const MAX_MORE_HEIGHT = 400
const MIN_HEIGHT = 37
const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

let interval
const PublishModal = ({ cancelCallback }) => {
  const descRef = useRef()
  const [showMoreDesc, setShowMoreDesc] = useState()
  const { scenario } = useContext(ScenarioEditorContext)
  const [heightOver, setHeightOver] = useState()
  const [editing, setEditing] = useState(false)
  const [previewImage, setPreviewImage] = useState(scenario.imageUrl)
  const [image, setImage] = useState(scenario.imageUrl)
  const [title, setTitle] = useState(scenario.name)
  const [scenarioCourses, setScenarioCourses] = useState()
  const [groupList, setGroupList] = useState([])
  const [showConfetti, setShowConfetti] = useState(false)

  // start confetti vars
  const duration = 60 * 1000
  const animationEnd = Date.now() + duration
  const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 9999,
    colors: [
      '#548ab9',
      '#70c8b8',
      '#e7461a',
      '#e7461a',
      '#e7461a',
      '#e7461a',
      '#e8c60a',
    ],
    shapes: ['circle'],
  }

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min
  }
  // end confetti vars

  const [updateScenario] = useMutation(updateScenarioMutation, {
    onError: handleApolloError,
  })

  const { data: coursesData } = useQuery(getCoursesQuery)

  const [publishScenario] = useMutation(publishScenarioMutation, {
    onError: handleApolloError,
    variables: {
      id: scenario.id,
      groupIds: groupList.map((group) => group.id) || [],
      courseIds: scenarioCourses?.map((c) => c.id),
    },
    onCompleted: () => {
      buildConfetti()
      setShowConfetti(true)
      setTimeout(() => {
        $('#publish-modal').foundation('close')
      }, 200);
    },
  })
  const [updateScenarioWithImage] = useMutation(updateScenarioImageMutation, {
    onError: handleApolloError,
  })

  const getScrollHeight = () => descRef.current?.scrollHeight

  useEffect(() => {
    setTimeout(() => {
      // calculate height after ending of transition
      setHeightOver(getScrollHeight() > MAX_HEIGHT)
    }, 100)
  }, [scenario])

  useEffect(() => {
    if (coursesData) {
      setScenarioCourses(
        coursesData.courses.filter((c) =>
          c.publishedScenarios.some((s) => s.scenario.id === scenario.id)
        )
      )
    }
  }, [coursesData])

  useEffect(() => {
    return () => {
      removeConfetti()
    }
  }, [])

  const removeConfetti = () => {
    clearInterval(interval)
    setTimeout(() => {
      setShowConfetti(false)
    }, 200)
  }

  const buildConfetti = () => {
    interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 100 * (timeLeft / duration)
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      })
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    }, 1000)
  }

  if (!coursesData) return null

  const publishHandler = () => {
    if (!editing) return publishScenario()

    const description = descRef.current.innerHTML

    if (image !== scenario.imageUrl) {
      const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
      upload.create((error, blob) => {
        if (error) throw new Error(error.message)

        updateScenarioWithImage({
          variables: {
            id: scenario.id,
            name: title,
            description,
            blobId: blob.signed_id || '',
          },
        }).then(() => {
          publishScenario()
        })
      })
    } else {
      updateScenario({
        variables: {
          id: scenario.id,
          name: title,
          description,
        },
      }).then(() => {
        publishScenario()
      })
    }
  }

  const renderForm = () => {
    if (editing)
      return (
        <>
          <div className="o-form__group">
            <label>Scenario title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="o-form__group">
            <label>Scenario description</label>
            <textarea
              ref={descRef}
              rows="6"
              defaultValue={scenario.description}></textarea>
          </div>
        </>
      )

    return (
      <>
        <h2 className="text-bold text-normal mb-2">{title}</h2>
        {scenario.description && (
          <div
            style={{
              minHeight: `${MIN_HEIGHT}px`,
              maxHeight: `${showMoreDesc ? MAX_MORE_HEIGHT : MAX_HEIGHT}px`,
              overflow: 'hidden',
              transition: 'all .2s',
            }}>
            <p className="text-dark" ref={descRef}>
              {scenario.description}
            </p>
          </div>
        )}
        {heightOver && (
          <button
            className="cursor-pointer text-underline text-stable-dark text-small mt-1 pl-1 bg-transparent tracking-tighter"
            onClick={() => setShowMoreDesc(!showMoreDesc)}>
            {showMoreDesc ? 'Show less' : 'Show more'}
          </button>
        )}
        <button
          className="o-button o-button--auto-width o-button--light text-bold"
          onClick={() => {
            setEditing(true)
          }}>
          Edit
        </button>
      </>
    )
  }

  return (
    <>
      <div
        hidden={!showConfetti}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#fff',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 9998,
        }}>
        <div>
          <h2 className="text-bold text-center">Yay, publishing starts now!</h2>
          <p className="text-center">
            We will notify you by email when publishing is done.
            <br />
            You can now close this window.
          </p>
          <div className="flex-container align-center">
            <a
              href="/scenarios"
              className="o-button o-button--auto-width text-normal text-bold"
              onClick={removeConfetti}>
              Close
            </a>
          </div>
        </div>
      </div>
      <Modal
        id="publish-modal"
        appendTo="#scenario-modals"
        headerText="Publish scenario"
        subtitle="Check scenario details and add to groups. New trainees that get access to this scenario will be notified be email after publishing is done."
        cancelHandler={cancelCallback}
        overflowY="visible"
        width={600}
        closeOnEscape
        footerText="Scenarios created with the new Flow cannot be played on a Pico G2 4K or Meta Quest 1 VR headset."
        banner={
          <div
            style={{
              backgroundImage: `url("${previewImage}")`,
            }}
            className="cell o-modal--content o-modal__scenario--image border-none">
            <label
              htmlFor="image-upload"
              className="button dark shadow dark-transparent">
              Change cover image
              <input
                type="file"
                id="image-upload"
                hidden
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  const file = event.target.files[0]
                  if (validateImageExtension(file)) {
                    setPreviewImage(URL.createObjectURL(file))
                    setImage(file)
                  } else {
                    addErrorAlert({
                      title: `Invalid image file type: ${file.type}`,
                      subtitle: 'Please upload a png or jpeg image',
                      timeout: 6000,
                    })
                  }
                }}
              />
            </label>
          </div>
        }
        buttons={
          <div className="flex-container align-middle">
            <button
              className="hollow button secondary o-button--auto-width"
              data-close="publish-modal">
              Cancel
            </button>
            <button
              type="submit"
              className="button primary o-button--auto-width"
              onClick={publishHandler}
              disabled={!title.length}>
              Publish
            </button>
          </div>
        }>
        <form className="mt-2">
          {renderForm()}

          <div
            className="border-bottom mt-3 mb-3"
            style={{ marginLeft: '-30px', marginRight: '-30px' }}
          />

          <PublishGroupsList
            groupList={groupList}
            setGroupList={setGroupList}
            scenario={scenario}
          />
        </form>
      </Modal>
    </>
  )
}
export default PublishModal
