import React from 'react'
import { useMutation } from '@apollo/client'
import { removeSceneMutation } from '../../../../apollo/query/scenes'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import Modal from '../../../UI/Modal'
import { useEdges, useNodes, useReactFlow } from 'reactflow'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'

const DeleteSceneModal = ({ id, appendTo, sceneId, cancelCallback }) => {
  const edges = useEdges()
  const reactFlow = useReactFlow()
  const nodes = reactFlow.getNodes()

  const { deleteElements } = reactFlow

  const [removeScene] = useMutation(removeSceneMutation, {
    onError: handleApolloError,
  })

  const { updateElement } = useAddUpdateElement()

  const handleRemoveScene = () => {
    let sceneIds = typeof sceneId === 'object' ? sceneId : [sceneId]
    let promises = []
    sceneIds.forEach((id) => {
      promises.push(removeScene({ variables: { id } }))
      const elements = nodes
        .find((n) => n.id === id)
        .data.elements.filter(
          (e) => e.linkToId === id || e.randomizedLinkToIds?.includes(id)
        )
      if (elements.length) {
        elements.forEach((element) => {
          promises.push(
            updateElement({
              variables: {
                id: element.id,
                linkToId: null,
                randomizedLinkToIds: element.randomizedLinkToIds?.map(
                  (linkId) => (linkId === id ? null : linkId)
                ),
              },
            })
          )
        })
      }
    })
    Promise.all(promises).then(() => {
      deleteElements({
        nodes: sceneIds.map((id) => ({ id })),
        edges: edges.filter((e) =>
          nodes.find(
            (n) =>
              sceneIds.includes(n.id) &&
              (n.id === e.source || n.id === e.target)
          )
        ),
      })

      addSuccessAlert({
        icon: 'success',
        title: `${sceneIds ? 'Scenes' : 'Scene'} successfully deleted`,
      })
    })
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this scene?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={cancelCallback}
      submitHandler={handleRemoveScene}
    />
  )
}
export default DeleteSceneModal
