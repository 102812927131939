import React from 'react'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'
import Button from '../../../Editor/Video/Elements/partials/Button'
import { getRotation } from '../../../Editor/helpers/controls'
import useHasHotspot from '../../../FlowV2/hooks/useHasHotspot'

const Information = ({ id, element, state, childElements }) => {
  const hasHotspot = useHasHotspot()
  if (!element) return null
  let labelWidth = 2.6
  if (element.labelSize === 'WIDE') labelWidth *= 2
  const collisionWidth = labelWidth + 0.5

  const buttonEl = childElements[0]

  return (
    <a-entity
      hideable-element={`visible: ${state.showElements}`}
      rotation={`${getRotation({ x: element.anchorX, y: element.anchorY })} 0`}>
      <a-collision-box
        id={id}
        width={collisionWidth}
        scroll-header-offset={hasHotspot ? 315 : 180}>
        <TextBubble
          textValue={element.label || 'Information'}
          id="question"
          width={labelWidth}
        />
        <a-entity position="0 0.1 0.01">
          <Button
            textValue={buttonEl.label || 'Button'}
            width={labelWidth}
            id="button"
          />
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default Information
