import React, { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import useFileUploader from '../../../hooks/useFileUploader'
import { getCroppedImg } from '../../../utils/helpers/canvas'
import RangeInput from '../../../components/UI/Form/RangeInput'
import Modal from '../../../components/UI/Modal'

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const ZOOM_MIN_MAX = [0.1, 5]

const LogoUploader = ({ uri, onUploadCompleted, title = 'Logo', id, type }) => {
  const [imageSrc, setImageSrc] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [uploadItems, setUploadItems] = useFileUploader()
  const [uploading, setUploading] = useState(false)
  const [file, setFile] = useState(false)

  const handleFileChange = async (e) => {
    const el = e.target
    if (el.files && el.files.length > 0) {
      $(`#${id}`).foundation('open')
      const file = el.files[0]
      setFile(file)
      let imageDataUrl = await readFile(file)
      el.value = ''
      setTimeout(() => {
        setImageSrc(imageDataUrl)
      }, 200)
    }
  }

  const handleUpload = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, 0)
    setUploadItems([new File([croppedImage], file.name)])
  }

  const handleCancel = () => {
    setImageSrc(null)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  useEffect(() => {
    const fileToUpload = uploadItems[uploadItems.length - 1]
    if (fileToUpload?.blobId) {
      onUploadCompleted(fileToUpload.blobId)
      setUploading(false)
    }else{
      setUploading(true)
    }
  }, [uploadItems])

  return (
    <>
      <div className="pr-2 pl-1 mb-1">
        <div>
          <label>{title}</label>
          <div
            className={`w-100 border-radius--rounded-full mt-1 border flex-container align-middle align-center
              ${type === 'nadir' && 'u-bg-dark'}`}
            style={{ width: '180px', height: '180px' }}>
            <img
              className="border-radius--rounded-full"
              src={uri}
              alt="Logo"
              height="150"
              width="150"
            />
          </div>
          <button className="o-button o-button--secondary w-100 o-file-input o-file-input--basic mt-1 text-bold">
            Upload logo
            <input
              className="o-files-drop"
              type="file"
              onChange={handleFileChange}
              accept=".png,.jpg"
            />
          </button>
        </div>
      </div>

      <Modal
        id={id}
        appendTo="#branding-logos"
        useDefaultButtons
        closeOnEscape={false}
        headerText="Upload new logo"
        submitHandler={handleUpload}
        cancelHandler={handleCancel}>
        <>
          {imageSrc && (
            <>
              <div style={{ width: '100%', height: 400, position: 'relative' }}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect="1 / 1"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                  minZoom={ZOOM_MIN_MAX[0]}
                  maxZoom={ZOOM_MIN_MAX[1]}
                  restrictPosition={false}
                  cropSize={{ width: 256, height: 256 }}
                  style={{
                    cropAreaStyle: {
                      color:
                        type === 'nadir'
                          ? 'rgba(0, 0, 0, 0.85)'
                          : 'rgba(0, 0, 0, 0.4)',
                    },
                  }}
                />
              </div>
              <div className="my-2">
                <RangeInput
                  showLabels={false}
                  showLabelsOnHoverOnly
                  minValue={ZOOM_MIN_MAX[0]}
                  updateOnDrag={true}
                  maxValue={ZOOM_MIN_MAX[1]}
                  value={zoom}
                  setValue={setZoom}
                />
              </div>
            </>
          )}
        </>
      </Modal>
    </>
  )
}
export default LogoUploader
