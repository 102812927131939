import { useMutation } from '@apollo/client'
import { createLoginSessionMutation } from '../apollo/query/user'
import { handleApolloError } from '../utils/errors'

const useCreateLoginSession = () => {
  const [createLoginSession] = useMutation(createLoginSessionMutation, {
    onError: handleApolloError,
    onCompleted: (data) => {
      window.open(data.createLoginSession.session.url)
    }
  })

  return createLoginSession
}

export default useCreateLoginSession
