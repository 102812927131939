import React from 'react'
import ScenarioSettingsForm from './General/ScenarioSettingsForm'
import CoverTripodForm from './General/CoverTripodForm'
import PdfDocumentForm from './General/PdfDocumentForm'
import VoiceControlForm from './General/VoiceControlForm'

const GeneralTab = () => {
  return (
    <>
      <ScenarioSettingsForm />
      <CoverTripodForm />
      <PdfDocumentForm />
      <VoiceControlForm />
    </>
  )
}
export default GeneralTab
