const config = {
  maxZoom: 2,
  minZoom: 0.06,
  zooms: [0.06, 0.13, 0.25, 0.5, 1.0, 2.0],
  videoZooms: [0.5, 1.0, 2.0],
  zoomDuration: 200,
  sceneNodeWidth: 270,
  sceneNodeMinHeight: 65,
  fitViewOptions: {
    maxZoom: 2,
    minZoom: 0.06,
  },
  newNodeDimensions: {
    width: 270,
    height: 56,
  },
  defaultHotspotSize: 'MEDIUM',
  onlyRenderVisibleElementsThreshold: 1
}
export default config
