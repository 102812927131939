import React, { useContext, useState } from 'react'
import { addSuccessAlert } from '../../../../../utils/helpers/alerts'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { useMutation } from '@apollo/client'
import { updateScenarioSettingsMutation } from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'
import { default as S } from '../../../../../utils/lang/en.json'

const CoverTripodForm = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [checked, setChecked] = useState(!scenario.hideNadir)
  const { id, voiceEnabled, locale } = scenario
  
  const finishUpdating = () => {
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${scenario.name}' were saved succesfully`,
    })
    refetch()
  }

  const [updateScenarioSettings] = useMutation(updateScenarioSettingsMutation, {
    onError: handleApolloError,
    onCompleted: finishUpdating,
  })

  const handleChange = (e) => {
    const checked = e.target.checked
    setChecked(checked)
    updateScenarioSettings({
      variables: {
        id,
        voiceEnabled,
        locale: locale ?? '',
        hideNadir: !checked,
      },
    })
  }

  return (
    <div className="row border-bottom pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Cover tripod</h2>
        <p className="mb-0">
          Do you like your logo being displayed at the bottom of the scenario
          (also called nadir) to cover a possible tripod?
        </p>
      </div>
      <div className="xlarge-6 medium-8 small-12 columns mt-0">
        <div className="flex-container align-middle align-justify">
          <label>Cover tripod with your own logo?</label>
          <div className="o-switch">
            <input
              id="coverTripod"
              type="checkbox"
              checked={checked}
              onChange={handleChange}
              className="o-switch__input"
            />
            <label htmlFor="coverTripod" className="o-switch__paddle"></label>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CoverTripodForm
