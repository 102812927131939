import { gql } from '@apollo/client'
import { SCENE_FIELDS, SCENE_FIELDS_V2 } from './fragments'

const getSceneQuery = gql`
  ${SCENE_FIELDS}
  query getScene($id: ID!) {
    scene(id: $id) {
      ...SceneFields
    }
  }
`

const getSceneQueryV2 = gql`
  ${SCENE_FIELDS_V2}
  query getScene($id: ID!) {
    scene(id: $id) {
      ...SceneFieldsV2
    }
  }
`

const getScenesQuery = gql`
  query getScenes($id: ID) {
    scenarios(id: $id) {
      scenes {
        name
        id
        number
        hasVideo
      }
    }
  }
`

const getCameraScenesQuery = gql`
  query getScenes($id: ID!) {
    scenarios(id: $id) {
      name
      scenesInFilmingOrder {
        id
        name
        description
        number
        filming
        start
        cameraLocation {
          id
          name
          description
        }
      }
    }
  }
`

const linkSceneMutation = gql`
  mutation link($mediumId: ID!, $sceneId: ID!) {
    linkToMediumToScene(mediumId: $mediumId, sceneId: $sceneId) {
      medium {
        id
        linkedToScenes {
          id
          name
          number
          scenario {
            id
            name
          }
        }
      }
    }
  }
`

const updateSceneMutation = gql`
  ${SCENE_FIELDS}
  mutation updateScene(
    $id: ID!
    $name: String!
    $description: String!
    $question: String
    $questionFadeIn: Float
    $videoLoopEnabled: Boolean!
    $videoLoop: Float
    $showQuestionInLookDirection: Boolean!
    $northOffsetEnabled: Boolean!
    $northOffset: Int
    $canvasX: Int!
    $canvasY: Int!
    $anchorX: Float
    $anchorY: Float
    $hideHotspotLabelsVr: Boolean!
    $hint: Boolean!
    $giveHintAfter: Int!
    $elementSize: ElementSize!
    $answerElementSize: ElementSize
  ) {
    updateScene(
      id: $id
      name: $name
      description: $description
      question: $question
      questionFadeIn: $questionFadeIn
      videoLoopEnabled: $videoLoopEnabled
      videoLoop: $videoLoop
      showQuestionInLookDirection: $showQuestionInLookDirection
      northOffsetEnabled: $northOffsetEnabled
      northOffset: $northOffset
      canvasX: $canvasX
      canvasY: $canvasY
      anchorX: $anchorX
      anchorY: $anchorY
      hideHotspotLabelsVr: $hideHotspotLabelsVr
      hint: $hint
      giveHintAfter: $giveHintAfter
      elementSize: $elementSize
      answerElementSize: $answerElementSize
    ) {
      scene {
        ...SceneFields
      }
    }
  }
`

const updateSceneMutationV2 = gql`
  ${SCENE_FIELDS_V2}
  mutation updateScene(
    $id: ID!
    $name: String!
    $description: String!
    $question: String
    $questionFadeIn: Float
    $videoLoopEnabled: Boolean!
    $videoLoop: Float
    $showQuestionInLookDirection: Boolean!
    $northOffsetEnabled: Boolean!
    $northOffset: Int
    $canvasX: Int!
    $canvasY: Int!
    $anchorX: Float
    $anchorY: Float
    $hideHotspotLabelsVr: Boolean!
    $hint: Boolean!
    $giveHintAfter: Int!
    $elementSize: ElementSize!
    $answerElementSize: ElementSize
  ) {
    updateScene(
      id: $id
      name: $name
      description: $description
      question: $question
      questionFadeIn: $questionFadeIn
      videoLoopEnabled: $videoLoopEnabled
      videoLoop: $videoLoop
      showQuestionInLookDirection: $showQuestionInLookDirection
      northOffsetEnabled: $northOffsetEnabled
      northOffset: $northOffset
      canvasX: $canvasX
      canvasY: $canvasY
      anchorX: $anchorX
      anchorY: $anchorY
      hideHotspotLabelsVr: $hideHotspotLabelsVr
      hint: $hint
      giveHintAfter: $giveHintAfter
      elementSize: $elementSize
      answerElementSize: $answerElementSize
    ) {
      scene {
        ...SceneFieldsV2
      }
    }
  }
`

const updateAnswerMutation = gql`
  mutation updateAnswer(
    $id: ID!
    $name: String!
    $anchorX: Float
    $anchorY: Float
    $points: Int!
    $ending: Boolean!
    $linkToId: ID
    $randomizedLinkToIds: [ID!]!
    $hideInVr: Boolean!
    $hotspotSize: HotspotSize
    $elementSize: ElementSize!
  ) {
    updateAnswer(
      id: $id
      name: $name
      anchorX: $anchorX
      anchorY: $anchorY
      points: $points
      ending: $ending
      linkToId: $linkToId
      randomizedLinkToIds: $randomizedLinkToIds
      hideInVr: $hideInVr
      hotspotSize: $hotspotSize
      elementSize: $elementSize
    ) {
      answer {
        id
        name
        anchorX
        anchorY
        points
        ending
        linkToId
        randomizedLinkToIds
        hideInVr
        hotspotSize
        elementSize
      }
    }
  }
`

const deleteSceneMutation = gql`
  mutation delete($mediumId: ID!, $sceneId: ID!) {
    unlinkToMediumToScene(mediumId: $mediumId, sceneId: $sceneId) {
      medium {
        id
        linkedToScenes {
          id
          name
          number
          scenario {
            id
            name
          }
        }
      }
    }
  }
`

const addSceneMutation = gql`
  mutation addScene(
    $scenarioId: ID!
    $name: String!
    $canvasX: Int!
    $canvasY: Int!
    $start: Boolean!
  ) {
    addScene(
      scenarioId: $scenarioId
      name: $name
      canvasX: $canvasX
      canvasY: $canvasY
      start: $start
    ) {
      scene {
        id
        canvasX
        canvasY
        name
        number
        start
      }
    }
  }
`

const updateSceneDetailsMutation = gql`
  mutation updateSceneDetails(
    $id: ID!
    $name: String!
    $description: String!
    $canvasX: Int!
    $canvasY: Int!
    $start: Boolean!
  ) {
    updateSceneDetails(
      id: $id
      name: $name
      description: $description
      canvasX: $canvasX
      canvasY: $canvasY
      start: $start
    ) {
      scene {
        id
        name
        description
        canvasX
        canvasY
        start
        number
      }
    }
  }
`

const removeSceneMutation = gql`
  mutation removeScene($id: ID!) {
    removeScene(id: $id) {
      id
    }
  }
`

const addElementMutation = gql`
  mutation addElement(
    $sceneId: ID!
    $number: Int!
    $kind: ElementKind!
    $groupKind: ElementGroupKind
    $groupUuid: ID
    $timerMinutes: Int
    $timerSeconds: Int
  ) {
    addElement(
      sceneId: $sceneId
      number: $number
      kind: $kind
      groupKind: $groupKind
      groupUuid: $groupUuid
      timerMinutes: $timerMinutes
      timerSeconds: $timerSeconds
    ) {
      element {
        id
        kind
        number
        groupKind
        groupUuid
        hotspotSize
        timerMinutes
        timerSeconds
        defaultAnchorX
        defaultAnchorY
        anchorX
        anchorY
        points
        linkToId
        linkToEnding
        randomizedLinkToIds
        label
        labelSize
        labelFont
        showLabel
        showInLookDirection
      }
    }
  }
`

const updateElementMutation = gql`
  mutation updateElement(
    $id: ID!
    $number: Int!
    $kind: ElementKind!
    $groupKind: ElementGroupKind
    $groupUuid: ID
    $linkToId: ID
    $linkToEnding: Boolean!
    $label: String!
    $labelSize: ElementSize!
    $showLabel: Boolean!
    $showInLookDirection: Boolean!
    $points: Int
    $anchorX: Float
    $anchorY: Float
    $hotspotSize: HotspotSize
    $timerMinutes: Int
    $timerSeconds: Int
    $randomizedLinkToIds: [ID!]!
  ) {
    updateElement(
      id: $id
      number: $number
      kind: $kind
      groupKind: $groupKind
      groupUuid: $groupUuid
      linkToId: $linkToId
      linkToEnding: $linkToEnding
      label: $label
      labelSize: $labelSize
      showLabel: $showLabel
      showInLookDirection: $showInLookDirection
      points: $points
      anchorX: $anchorX
      anchorY: $anchorY
      hotspotSize: $hotspotSize
      timerMinutes: $timerMinutes
      timerSeconds: $timerSeconds
      randomizedLinkToIds: $randomizedLinkToIds
    ) {
      element {
        id
        number
        kind
        anchorX
        anchorY
        groupKind
        groupUuid
        points
        linkToId
        linkToEnding
        randomizedLinkToIds
        label
        labelSize
        labelFont
        showLabel
        showInLookDirection
        hotspotSize
        timerMinutes
        timerSeconds
      }
    }
  }
`

const removeElementMutation = gql`
  mutation removeElement($id: ID!) {
    removeElement(id: $id) {
      id
    }
  }
`

const updateLinkMediumToSceneMutation = gql`
  mutation linkMediumToScene($sceneId: ID!, $mediumId: ID!) {
    linkToMediumToScene(sceneId: $sceneId, mediumId: $mediumId) {
      medium {
        id
      }
    }
  }
`

const updateUnlinkMediumToSceneMutation = gql`
  mutation unlinkMediumToScene($sceneId: ID!, $mediumId: ID!) {
    unlinkToMediumToScene(sceneId: $sceneId, mediumId: $mediumId) {
      medium {
        id
      }
    }
  }
`

const updateSceneFilmingMutation = gql`
  mutation updateSceneFilming(
    $id: ID!
    $filming: Boolean!
    $cameraLocationId: ID
    $useSameVideoAsId: ID
  ) {
    updateSceneFilming(
      id: $id
      filming: $filming
      cameraLocationId: $cameraLocationId
      useSameVideoAsId: $useSameVideoAsId
    ) {
      scene {
        id
        filming
        cameraLocation {
          id
          name
        }
        useSameVideoAsId
      }
    }
  }
`

const updateScenesFilmingOrderMutation = gql`
  mutation updateScenesFilmingOrder($id: ID!, $sceneIds: [ID!]!) {
    updateScenesFilmingOrder(id: $id, sceneIds: $sceneIds) {
      scenario {
        id
        scenesInFilmingOrder {
          id
          name
          number
          filming
          start
          cameraLocation {
            id
            name
            description
          }
        }
      }
    }
  }
`

export {
  getSceneQuery,
  getSceneQueryV2,
  getScenesQuery,
  getCameraScenesQuery,
  linkSceneMutation,
  updateSceneMutation,
  updateSceneMutationV2,
  updateAnswerMutation,
  deleteSceneMutation,
  addSceneMutation,
  updateSceneDetailsMutation,
  removeSceneMutation,
  addElementMutation,
  updateElementMutation,
  removeElementMutation,
  updateLinkMediumToSceneMutation,
  updateUnlinkMediumToSceneMutation,
  updateSceneFilmingMutation,
  updateScenesFilmingOrderMutation,
}
