import React, { useContext, useState } from 'react'
import { addSuccessAlert } from '../../../../../utils/helpers/alerts'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { useMutation } from '@apollo/client'
import { updateScenarioPdfMutation } from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'
import { default as S } from '../../../../../utils/lang/en.json'
import { DirectUpload } from '@rails/activestorage'
import { FaTrash } from 'react-icons/fa'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

const PdfDocumentForm = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [file, setFile] = useState()
  const [title, setTitle] = useState(scenario.pdf?.name)
  const [updating, setUpdating] = useState()
  const [showForm, setShowForm] = useState(!scenario.pdf?.name)

  const finishUpdating = () => {
    setUpdating(false)
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${scenario.name}' were saved succesfully`,
    })
    refetch()
    setShowForm(false)
  }

  const [updateScenarioPdf] = useMutation(updateScenarioPdfMutation, {
    onError: handleApolloError,
    onCompleted: finishUpdating,
  })

  const renderForm = () => {
    if (!showForm)
      return (
        <div className="xlarge-6 medium-8 small-12 columns mt-0">
          <label>PDF document</label>
          <div className="text-normal pl-2 pr-2 pt-1-5 pb-1-5 flex-container align-middle align-justify border-light border-radius mb-1">
            <div>
              {scenario.pdf.name}
              <span className="text-stable-dark">
                &nbsp;•&nbsp; {scenario.pdf.url.split('/').pop()}
              </span>
            </div>
            <a
              className="text-assertive"
              title="Delete"
              onClick={() => setShowForm(true)}>
              <FaTrash />
            </a>
          </div>
          <p className="mb-0">
            Delete this PDF document first to be able to upload a new one.
          </p>
        </div>
      )

    const handleUploadPdf = (blob) => {
      updateScenarioPdf({
        variables: {
          id: scenario.id,
          name: title,
          ...(blob ? { blobId: blob.signed_id || '' } : {}),
        },
      })
    }

    const handleFormSubmit = (e) => {
      e.preventDefault()
      setUpdating(true)
      if (file) {
        const upload = new DirectUpload(file, DIRECT_UPLOAD_URL)
        return upload.create((error, blob) => {
          if (error) throw new Error(error.message)
          handleUploadPdf(blob)
        })
      }
      return handleUploadPdf()
    }

    const openInputFileHandler = (e) => {
      e.preventDefault()
      e.stopPropagation()
      document.querySelector('#browse-files').click()
    }

    return (
      <form
        onSubmit={handleFormSubmit}
        className="xlarge-6 medium-8 small-12 columns mt-0">
        <label>Upload PDF document</label>
        <div className="flex-container mb-2">
          <div className="w-100">
            <input
              type="text"
              className="cursor-pointer h-100 mb-0"
              readOnly
              onClick={openInputFileHandler}
              value={file?.name}
            />
            <input
              type="file"
              id="browse-files"
              hidden
              accept=".pdf"
              onChange={({ target: { files } }) => {
                setFile(files[0])
              }}
            />
          </div>
          <button
            className="button wide secondary hollow text-bold o-button--auto-width ml-1 mb-0"
            onClick={openInputFileHandler}>
            Browse
          </button>
        </div>

        <label>Title of the document</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <button
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal mb-0 ${updating ? 'o-button--loading' : ''}`}
          disabled={updating || !title || !file}
          aria-hidden="true">
          Confirm
        </button>
      </form>
    )
  }

  return (
    <div className="row border-bottom pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">PDF document</h2>
        <p className="mb-0">
          You can add a PDF document to a scenario, which can be viewed at any
          time by the trainee. The document can be accessed by a button which is
          always present at the bottom of the screen.
        </p>
      </div>
      {renderForm()}
    </div>
  )
}
export default PdfDocumentForm
