import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useLazyQuery } from '@apollo/client'
import { FaCheck } from 'react-icons/fa'
import Modal from '../../UI/Modal'
import {
  createDeviceRegistration,
  cancelRegistrationMutation,
  confirmDeviceRegistrationMutation,
  registrationQuery,
} from '../../../apollo/query/devices'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import cache from '../../../apollo/cache'

const AddSharedDeviceModal = ({ id, appendTo, refetchDevices }) => {
  const timeoutForPolling = 4 * 1000 // 4 seconds timeout before polling
  const pollInterval = 2 * 1000 // poll every 2 seconds
  const [key, setKey] = useState(uuidv4())
  const [currentCode, setCurrentCode] = useState(null)
  const [isPolling, setIsPolling] = useState(false)
  const [waitForDeviceConnection, setWaitForDeviceConnection] = useState(false)
  const [isDeviceConnected, setIsDeviceConnected] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [deviceName, setDeviceName] = useState('')

  const [
    pollRegistration,
    { data: registrationData, startPolling, stopPolling },
  ] = useLazyQuery(registrationQuery)

  const [cancelRegistration] = useMutation(cancelRegistrationMutation, {
    onError: handleApolloError,
  })

  const [createRegistration] = useMutation(createDeviceRegistration, {
    onError: handleApolloError,
    onCompleted: ({ createRegistration: { registration } }) => {
      setCurrentCode(registration.code)
      pollRegistration({ variables: { id: registration.id } })
      setWaitForDeviceConnection(true)
    },
  })
  const [confirmDeviceRegistration] = useMutation(
    confirmDeviceRegistrationMutation,
    {
      onError: handleApolloError,
      onCompleted: () => {
        addSuccessAlert({
          title: `Device registered as ${deviceName}`,
        })
        refetchDevices()
      },
    }
  )
  const resetState = () => {
    setCurrentCode(null)
    setIsPolling(false)
    setWaitForDeviceConnection(false)
    setIsDeviceConnected(false)
    setIsSessionExpired(false)
    setDeviceName('')
    cache.writeQuery({ query: registrationQuery, data: null })
  }

  const submit = () => {
    confirmDeviceRegistration({
      variables: {
        id: registrationData.registration.id,
        name: deviceName,
      },
    })
    setTimeout(() => {
      resetState()
      setKey(uuidv4())
    }, 300)
  }
  const cancel = () => {
    if (registrationData?.registration) {
      cancelRegistration({
        variables: { id: registrationData.registration.id },
      })
    }
    setTimeout(() => {
      if (isPolling) stopPolling()
      resetState()
      setKey(uuidv4())
    }, 300)
  }
  useEffect(() => {
    if (!isDeviceConnected && !isPolling && waitForDeviceConnection) {
      setTimeout(() => {
        setIsPolling(true)
        startPolling(pollInterval)
      }, timeoutForPolling)
    }
    if (registrationData?.registration.state === 'CONFIRMATION_REQUIRED') {
      setIsDeviceConnected(true)
      setWaitForDeviceConnection(false)
      setIsPolling(false)
      stopPolling()
    }
    if (registrationData?.registration.state === 'EXPIRED') {
      setIsPolling(false)
      setWaitForDeviceConnection(false)
      setIsDeviceConnected(false)
      setIsSessionExpired(true)
      stopPolling()
    }
  }, [registrationData])

  const showDeviceConnected = () => (
    <div className="cell small-12">
      <p
        className="text-bold text-dark o-alert--success"
        style={{
          borderRadius: '5px',
          display: 'grid',
          placeItems: 'center',
        }}>
        New {registrationData?.registration.device?.brand} device connected
      </p>
    </div>
  )

  const showSessionExpired = () => (
    <div className="cell small-12">
      <p
        className="text-bold text-dark o-alert--info"
        style={{
          display: 'flex',
          padding: '10px',
          borderRadius: '5px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        Session Expired{' '}
        <span
          className="text-normal cursor-pointer text-underline"
          onClick={() => {
            setCurrentCode(null)
            setIsSessionExpired(false)
            createRegistration()
          }}>
          Click here for a new code
        </span>
      </p>
    </div>
  )

  const showCode = (
    <div className="cell small-12">
      {currentCode ? (
        <>
          <span id="code" className="c-launcher__sign-in--code code-flex  mb-2">
            {currentCode}
          </span>
          <p>This code is valid for 10 minutes and can only be used once.</p>
        </>
      ) : (
        <button
          className="button hollow secondary"
          onClick={(e) => {
            e.preventDefault()
            createRegistration()
          }}>
          Generate Code
        </button>
      )}
    </div>
  )

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      headerText="New shared device"
      subtitle="Register a new shared device"
      submitHandler={submit}
      submitDisabled={!isDeviceConnected || !deviceName}
      cancelHandler={cancel}>
      <form key={key} className="mt-3">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <label htmlFor="add-shared-device-code">
              {isDeviceConnected && <FaCheck className="mr-1 text-energized" />}
              Step 1: Fill in code
            </label>
            <p>
              Fill in this code in the device to start the registration process.
            </p>
          </div>
          {isDeviceConnected ? showDeviceConnected() : showCode}
          {isSessionExpired && showSessionExpired()}
          <div className="cell small-12 mt-2">
            <label
              className="c-form__label--required"
              htmlFor="add-shared-device-name">
              Step 2: Enter device name
            </label>
            <input
              type="text"
              name="add-shared-device-name"
              id="add-shared-device-name"
              required
              value={deviceName}
              onChange={({ target }) => setDeviceName(target.value)}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddSharedDeviceModal
