import React, { useContext, useEffect, useState } from 'react'
import { FaChevronDown, FaPlus } from 'react-icons/fa'
import SearchableDropdown from '../../../UI/Menu/SearchableDropdown'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { handleApolloError } from '../../../../utils/errors'
import {
  addCameraLocationMutation,
} from '../../../../apollo/query/cameraLocations'
import { useMutation } from '@apollo/client'
import { updateSceneFilmingMutation } from '../../../../apollo/query/scenes'

const FilmingLocationsDropdown = ({ selectedNode }) => {
  let timer
  const scene = selectedNode.data
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [searchKey, setSearchKey] = useState('')
  const [show, setShow] = useState(false)

  const [addCameraLocation] = useMutation(addCameraLocationMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: refetch
  })

  const handleChangeElement = (cameraLocation) => {
    updateSceneFilming({
      variables: {
        id: scene.id,
        cameraLocationId: cameraLocation.id,
        useSameVideoAsId: scene.useSameVideoAsId,
        filming: scene.filming,
      },
    })
  }

  const footer = (
    <div
      className="flex-container align-middle padding-vertical-1 text-stable-dark"
      onClick={() =>
        addCameraLocation({
          variables: {
            scenarioId: scenario.id,
            name: searchKey,
            description: ''
          },
        })
      }>
      <FaPlus className="mr-1" />
      <div>
        <span className="text-bold">{'New location with the name '}</span>
        <span className="text-dark">{searchKey}</span>
      </div>
    </div>
  )

  const showSearchDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => setShow(true), 500)
  }

  const hideDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setShow(false)
    }, 500)
  }

  useEffect(() => {
    document.querySelector('#flow-container > aside').style.overflow = show
      ? 'visible'
      : 'auto'
  }, [show])

  return (
    <div
      style={{ position: 'relative', width: '50%' }}
      onMouseEnter={showSearchDropdown}
      onMouseLeave={hideDropdown}>
      <button
        className="o-button o-button--square-small o-button--light flex-container align-middle mb-0 pl-1 pr-1 w-100 text-normal"
        style={{ justifyContent: 'space-between' }}
        onClick={() => setShow(!show)}>
        <span className="text-bold">
          {scene?.cameraLocation?.name ?? 'Select...'}
        </span>
        <FaChevronDown className="ml-1" />
      </button>
      {show && (
        <SearchableDropdown
          placeholder="Search or create a filming location"
          clickHandler={(cameraLocation) => {
            setShow(false)
            handleChangeElement(cameraLocation)
          }}
          hideHandler={() => setShow(false)}
          notFoundTitle="No filming locations found"
          notFoundText={
            <small>You don't have any filming locations to add</small>
          }
          data={scenario.cameraLocations.map((c) => ({
            ...c,
            value: c.name,
            render: c.name,
          }))}
          onChangeSearchKey={setSearchKey}
          footer={searchKey && footer}
          show={show}
          position="right"
        />
      )}
    </div>
  )
}

export default FilmingLocationsDropdown
