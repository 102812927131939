const qS = (elem) => document.querySelector(elem)

export const resetCamera = () => {
  const controls = qS('a-camera').components['look-controls']
  controls.pitchObject.rotation.x = 0
  controls.yawObject.rotation.y = 0
}

export const moveCameraToPosition = (x, y, deg = true) => {
  const camera = qS('a-camera')
  const controls = camera.components['look-controls']
  if (deg) {
    controls.pitchObject.rotation.x = window.THREE.MathUtils.degToRad(x)
    controls.yawObject.rotation.y = window.THREE.MathUtils.degToRad(y)
  } else {
    controls.pitchObject.rotation.x = x
    controls.yawObject.rotation.y = y
  }

  camera.setAttribute('look-controls', 'enabled', false)

  // Add animation effect
  camera.setAttribute('animation', {
    property: 'rotation',
    to: `${x} ${y}`,
    dur: 200,
    easing: 'easeInOutQuad',
  })
  setTimeout(() => {
    camera.setAttribute('look-controls', 'enabled', true)
  }, 200)
}

export const moveElementToCameraPosition = (element) => {
  const rotation = qS('a-camera').getAttribute('rotation')
  const { x, z } = rotation
  let y = rotation.y % 360
  if (y > 180) y = -(360 - y)
  element.setAttribute('rotation', { x: x.toFixed(5), y: y.toFixed(5), z })
}

export const degsToCoords = ({ xDeg, yDeg }) => {
  let y = Number(-((xDeg - 90) / 180).toFixed(5))
  let x = Number(-((yDeg - 180) / 180).toFixed(5))
  if (Object.is(y, -0)) y = 0
  if (Object.is(x, -0)) x = 0
  return { x, y }
}

const coordsToDeg = ({ x, y }) => {
  // NOTE: This formula differs from the coords to degrees fn() in the app. The camera differences require a different formula

  let yDeg = -(x * 180 - 180).toFixed(5)
  let xDeg = -(y * 180 - 90).toFixed(5)
  if (Object.is(yDeg, -0)) yDeg = 0
  if (Object.is(xDeg, -0)) xDeg = 0
  return { x: xDeg, y: yDeg }
}

export const getRotationFromCoords = ({ x, y }) => {
  const { x: xOffset, y: yOffset } = coordsToDeg({ x, y })
  return `${xOffset} ${yOffset}`
}
export const getRotation = ({ x, y }) => {
  const xOffset = -x || 0
  const yOffset = y || 0
  return `${yOffset} ${xOffset}`
}

export const unSelectCollistionBox = () => {
  ;[...document.querySelectorAll('a-collision-box.selected')].forEach((el) => {
    el.emit('intersect-cleared')
    el.emit('is-selected', { isSelected: false })
  })
}
