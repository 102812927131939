import { useMutation } from '@apollo/client'
import { updateScenarioScoringSystemMutation } from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'

const useUpdateScoringSystem = () => {
  const [_updateScenarioScoringSystem] = useMutation(
    updateScenarioScoringSystemMutation,
    {
      onError: handleApolloError,
    }
  )

  const updateScoringSystem = ({ variables, ...props }) => {
    const { id, scoringSystem } = variables
    const {
      kind,
      pointsPerStar,
      starsForCompletion,
      noStarDescription,
      oneStarDescription,
      twoStarDescription,
      threeStarDescription,
      fourStarDescription,
      fiveStarDescription,
    } = scoringSystem
    console.log(starsForCompletion)
    return _updateScenarioScoringSystem({
      ...props,
      variables: {
        id,
        scoringSystem: {
          kind,
          pointsPerStar,
          starsForCompletion,
          noStarDescription,
          oneStarDescription,
          twoStarDescription,
          threeStarDescription,
          fourStarDescription,
          fiveStarDescription,
        },
      },
    })
  }

  return updateScoringSystem
}

export default useUpdateScoringSystem
