import { gql } from '@apollo/client'

const getUserSearchInfoQuery = gql`
  query users {
    users {
      id
      lastName
      firstName
      email
    }
  }
`

const getAllGroupsQuery = gql`
  query getGroups {
    groups {
      id
      name
      automated
      createdAt
      publicLinkSecurityType
      publicLinkEnabled
      publicLinkUrl
      courses {
        id
        name
      }
      publishedScenarios {
        id
        name
      }
      userIds
    }
  }
`

const getGroupsQuery = gql`
  query getGroups {
    groups(automated: false) {
      id
      name
      automated
      createdAt
      publicLinkEnabled
      publicLinkSecurityType
      publicLinkDomains
      publicLinkUrl
      courses {
        id
        name
      }
      publishedScenarios {
        id
        name
      }
      userIds
      startDate
      endDate
    }
  }
`
const getGroupNamesQuery = gql`
  query getGroupNames {
    groups {
      id
      name
      automated
      courses {
        name
      }
      publishedScenarios {
        id
        name
      }
      userIds
      startDate
      endDate
    }
  }
`

const addGroupMutation = gql`
  mutation addGroup(
    $name: String!
    $publishedScenarioIds: [Int!]!
    $courseIds: [Int!]!
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    addGroup(
      name: $name
      publishedScenarioIds: $publishedScenarioIds
      courseIds: $courseIds
      startDate: $startDate
      endDate: $endDate
    ) {
      group {
        id
        name
        publishedScenarios {
          id
          name
        }
        courses {
          id
          name
        }
        publicLinkEnabled
        publicLinkUrl
        userIds
        startDate
        endDate
      }
    }
  }
`

const addGroupsImportMutation = gql`
  mutation addImport($kind: ImportKind!, $blobId: String!, $groupIds: [ID!]) {
    addImport(kind: $kind, blobId: $blobId, groupIds: $groupIds) {
      import {
        id
        errorMessages
        kind
        progress
        state
      }
    }
  }
`

const updateGroupMutation = gql`
  mutation updateGroup(
    $id: ID!
    $name: String!
    $publishedScenarioIds: [Int!]!
    $courseIds: [Int!]!
    $publicLinkEnabled: Boolean!
    $publicLinkSecurityType: PublicLinkSecurityType
    $publicLinkDomains: [String!]!
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    updateGroup(
      id: $id
      name: $name
      publishedScenarioIds: $publishedScenarioIds
      courseIds: $courseIds
      publicLinkEnabled: $publicLinkEnabled
      publicLinkSecurityType: $publicLinkSecurityType
      publicLinkDomains: $publicLinkDomains
      startDate: $startDate
      endDate: $endDate
    ) {
      group {
        id
        name
        publishedScenarios {
          id
          name
        }
        courses {
          id
          name
        }
        publicLinkSecurityType
        publicLinkEnabled
        publicLinkDomains
        publicLinkUrl
        userIds
        startDate
        endDate
      }
    }
  }
`

const deleteGroupMutation = gql`
  mutation removeGroup($id: ID!) {
    removeGroup(id: $id) {
      id
    }
  }
`
const addUserToGroupsMutation = gql`
  mutation addGroupMembership($userIds: [ID!]!, $groupIds: [ID!]!) {
    addGroupMembership(userIds: $userIds, groupIds: $groupIds) {
      groups {
        id
        userIds
      }
    }
  }
`

const removeUserFromGroupsMutation = gql`
  mutation removeGroupMembership($userIds: [ID!]!, $groupIds: [ID!]!) {
    removeGroupMembership(userIds: $userIds, groupIds: $groupIds) {
      groups {
        id
        userIds
      }
    }
  }
`

export {
  getGroupsQuery,
  getAllGroupsQuery,
  getGroupNamesQuery,
  getUserSearchInfoQuery,
  addGroupMutation,
  updateGroupMutation,
  deleteGroupMutation,
  addGroupsImportMutation,
  addUserToGroupsMutation,
  removeUserFromGroupsMutation,
}
