import { gql } from '@apollo/client'

const getActivitiesQuery = gql`
  query activities {
    activities {
      user {
        id
        administrator
        firstName
        lastName
      }
      role
      action
      createdAt
      metadata {
        key
        value
      }
    }
  }
`

const exportActivityQuery = gql`
  mutation exportActivity {
    exportActivity {
      success
    }
  }
`

export { getActivitiesQuery, exportActivityQuery }
