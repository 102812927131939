import config from '../config/config'
import { newEdgeId, restoreHiddenEdges, scenesToEdges } from './edgeHelper'
import { INTERACTIVE_ELEMENT_TYPES } from './elementHelper'
import {
  useEdges,
  useNodes,
  useReactFlow,
  useUpdateNodeInternals,
} from 'reactflow'

export const newNodeId = 'new-node'

export const scenesToNodes = (reactFlow, scenes, location) => {
  // add scene nodes
  const regex = /\/scenes\/(\d+).*$/
  const match = location.pathname.match(regex)
  let selectedSceneId
  if (match) selectedSceneId = match[1]

  const nodes = scenes
    .map((scene) => ({
      id: scene.id,
      selected: scene.id === selectedSceneId,
      type: 'Scene',
      position: { x: scene.canvasX, y: scene.canvasY },
      data: {
        ...scene,
        description: scene.description ?? '',
      },
    }))
    .sort((a, b) => a.position.y - b.position.y)
  reactFlow.setNodes(nodes)
  const edges = scenesToEdges(scenes)
  reactFlow.setEdges(edges)
  return nodes
}

const getNodeHandles = (nodes, nodeData, edges) => {
  const selectedEdges = edges.filter((n) => n.selected)

  return {
    source: nodeData.elements
      ? [
          ...nodeData.elements
            .filter(
              (el) =>
                INTERACTIVE_ELEMENT_TYPES.includes(el.kind) &&
                !el.randomizedLinkToIds?.length
            )
            .map((el) => ({
              id: `e${nodeData.id}-${el.id}`,
              points: el.points,
              selected: selectedEdges.find((edge) => edge.id === el.id),
            })),
          // Add randomizer handles
          ...nodeData.elements
            .filter((el) => el.randomizedLinkToIds?.length)
            .map((el) =>
              el.randomizedLinkToIds
                .map((sceneId, index) => {
                  const handleId = `e${nodeData.id}-${el.id}-${index}`
                  return {
                    id: handleId,
                    elementId: el.id,
                    points: el.points,
                    selected: selectedEdges.find(
                      (edge) => edge.sourceHandle === handleId
                    ),
                    isRandomized: true,
                    randomizeIndex: index,
                  }
                })
                .filter((sceneId) => !!sceneId)
            )
            .flat(),
        ]
      : [],
    target: [
      ...nodes
        .filter((node) => node.id !== nodeData.id && node.data.elements)
        .map((node) => node.data.elements)
        .flat()
        .filter(
          (el) => el.linkToId === nodeData.id && !el.randomizedLinkToIds?.length
        )
        .filter((el) => {
          // hide handles that related edge is hidden
          const relatedEdge = edges.find(
            (e) => e.id === el.id || el.id === e.oldId
          )
          return !relatedEdge || !relatedEdge.data.customHidden
        })
        .map((el) => ({
          id: `e${nodeData.id}-${el.id}`,
          points: el.points,
          selected: selectedEdges.find((e) => e.id === el.id),
        })),

      // Add randomizer handles
      ...nodes
        .filter((node) => node.id !== nodeData.id && node.data.elements)
        .map((node) => node.data.elements)
        .flat()
        .filter((el) => el.randomizedLinkToIds?.length)
        .map((el) =>
          el.randomizedLinkToIds
            .map((sceneId, index) => {
              if (!sceneId || sceneId !== nodeData.id) return null
              const handleId = `e${nodeData.id}-${el.id}-${index}`
              return {
                id: handleId,
                points: el.points,
                selected: selectedEdges.find(
                  (e) => e.targetHandle === handleId
                ),
              }
            })
            .filter((sceneId) => !!sceneId)
        )
        .flat(),
    ],
  }
}

export const useSortedNodeHandles = (nodeData) => {
  const nodes = useNodes()
  const edges = useEdges()
  return getNodeHandles(nodes, nodeData, edges)
}

export const getHandles = (nodes, edges) => {
  const allHandles = nodes.map((node) =>
    getNodeHandles(nodes, node.data, edges)
  )
  return {
    source: allHandles.map((h) => h.source).flat(),
    target: allHandles.map((h) => h.target).flat(),
  }
}

export const useAddNewNodePlaceholder = () => {
  const updateNodeInternals = useUpdateNodeInternals()
  const reactFlow = useReactFlow()

  return (position, setNodes, scenarioId) => {
    const edges = reactFlow.getEdges()
    const sourceNode = edges.find((e) => e.id === newEdgeId)?.source
    const newNode = {
      id: newNodeId,
      position,
      type: 'New',
      selectable: false,
      data: { scenarioId },
    }
    setNodes((nds) => nds.concat(newNode))
    updateNodeInternals(sourceNode)
  }
}

export const useRemoveNewNodePlaceholder = () => {
  const updateNodeInternals = useUpdateNodeInternals()
  const reactFlow = useReactFlow()
  
  return () => {
    const edges = reactFlow.getEdges()
    const sourceNode = edges.find((e) => e.id === newEdgeId)?.source
    const targetNode = edges.find((e) => e.id === newEdgeId)?.targetNode

    reactFlow.deleteElements({
      nodes: [{ id: newNodeId }],
      edges: [{ id: newEdgeId }],
    })
    if (sourceNode) updateNodeInternals(sourceNode)
    if (targetNode) updateNodeInternals(targetNode)
  }
}

const getNodeHeight = (id) =>
  document.querySelector(`.react-flow__nodes > [data-id="${id}"]`)
    ?.offsetHeight ?? config.sceneNodeMinHeight

export const setCentralNodeAndSelectIt = (reactFlow, node, zoom) => {
  setCenteral(reactFlow, node, zoom)
  reactFlow.setNodes(
    reactFlow.getNodes().map((n) => ({ ...n, selected: n.id === node.id }))
  )
}

export const setCenteral = (reactFlow, node, zoom) => {
  const viewPort = reactFlow.getViewport()
  zoom || (zoom = viewPort.zoom)
  reactFlow.setCenter(
    node.position.x + config.sceneNodeWidth / 2,
    node.position.y + getNodeHeight(node.id) / 2,
    {
      duration: config.zoomDuration,
      zoom,
    }
  )
}

export const setNode = (reactFlow, node) => {
  const newNode = { ...node }
  if (newNode.data?.elements) {
    newNode.data.elements = [...newNode.data.elements].sort(
      (a, b) => a.number - b.number
    )
  }
  reactFlow.setNodes(
    reactFlow.getNodes().map((n) => (n.id === newNode.id ? newNode : n))
  )
}

export const selectNodes = (reactFlow, nodeIds) => {
  const nodes = reactFlow.getNodes()
  const selectedNodes = nodes.filter((node) => nodeIds.includes(node.id))
  if (selectedNodes) {
    reactFlow.setNodes(
      nodes.map((node) => ({
        ...node,
        selected: nodeIds.includes(node.id),
      }))
    )
  }
}

export const isMultiSelection = () =>
  document.querySelector('.react-flow__pane').classList.contains('selection')

export const getConnectedNodes = (reactFlow, targetNodeId) => {
  const edges = reactFlow.getEdges()
  const connectedNodes = edges
    .filter((edge) => edge.target === targetNodeId)
    .map((edge) => edge.target)

  const uniqueConnectedNodes = [...new Set(connectedNodes)]
  return uniqueConnectedNodes
}
