import React, { useContext } from 'react'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { Handle, Position, useEdges } from 'reactflow'
import ElementLetterLabel from './ElementLetterLabel'
import {
  getPointClass,
  INTERACTIVE_ELEMENT_TYPES,
} from '../../../helpers/elementHelper'
import { isMultiSelection } from '../../../helpers/nodeHelper'
import EndingNode from '../Node/EndingNode'
import PointLabel from './PointLabel'
import SceneToNumberLabel from './SceneToNumberLabel'

const LinkElement = ({
  element,
  sourceHandle,
  isConnected,
  linkToEnding,
  parentIndex,
  childIndex,
  isExport,
  isRandomizer,
}) => {
  const edges = useEdges()

  const { scenario } = useContext(ScenarioEditorContext)

  const edge = sourceHandle
    ? edges.find((e) => e.sourceHandle === sourceHandle.id)
    : null

  const renderNumberLabel = (linkToId) => {
    if (!linkToId) return <></>
    const linkedScene = scenario.scenes.find((n) => n.id === linkToId)
    if (!linkedScene) return <></>
    return <SceneToNumberLabel number={linkedScene.number} className="ml-0-5" />
  }

  if (!sourceHandle && !isExport) return <></>

  return (
    <div>
      <div className="flex-container align-middle">
        {(!isExport ||
          isRandomizer ||
          (INTERACTIVE_ELEMENT_TYPES.includes(element.kind) &&
            !element.randomizedLinkToIds?.length)) && (
          <ElementLetterLabel
            className="ml-1"
            index={parentIndex + (childIndex ?? 0) + 1}
          />
        )}

        {isExport && (
          <>
            <PointLabel className="ml-0-5" points={element.points} />
            {renderNumberLabel(element.linkToId)}
          </>
        )}

        {!isExport && linkToEnding && <EndingNode points={element.points} />}
      </div>

      {sourceHandle && (
        <Handle
          type="source"
          position={Position.Right}
          id={sourceHandle.id}
          isConnectableStart={!isConnected}
          isConnectableEnd={!isConnected}
          className={`o-scene__handle o-scene__handle--source
              o-scene__handle--${getPointClass(sourceHandle.points, scenario.scoringSystem.kind)} 
              ${isConnected ? 'o-scene__handle--connected' : ''}
              ${!isMultiSelection() && sourceHandle.selected && edge.data.selectType !== 'hover' ? 'o-scene__handle--selected' : ''}
            `}
        />
      )}
    </div>
  )
}

export default LinkElement
