import React from 'react'
import {
  FaArrowUp,
  FaBars,
  FaComment,
  FaCommentAlt,
  FaDotCircle,
  FaInfo,
  FaMinus,
  FaRandom,
  FaRedo,
  FaStopwatch,
} from 'react-icons/fa'

export const ELEMENT_TYPES = [
  { type: 'MPC', title: 'Multiple choice', icon: <FaBars /> },
  { type: 'INFORMATION', title: 'Information', icon: <FaInfo /> },
  { type: 'DIRECTION', title: 'Direction', icon: <FaArrowUp /> },
  { type: 'HOTSPOT', title: 'Hotspot', icon: <FaDotCircle /> },
  { type: 'BUTTON', title: 'Button', icon: <FaMinus /> },
  { type: 'QUESTION', title: 'Question', icon: <FaCommentAlt />, hide: true },
  { type: 'ANSWER', title: 'Answer', icon: <FaMinus />, hide: true },
  { type: 'TIMER', title: 'Timer', icon: <FaStopwatch /> },
  { type: 'TRANSITION', title: 'Auto-transition', icon: <FaRedo /> },
  { type: 'TOOLTIP', title: 'Tooltip', icon: <FaComment /> },
  { type: 'RANDOMIZER', title: 'Randomizer', icon: <FaRandom />, hide: true },
]

export const INTERACTIVE_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TIMER',
  'TRANSITION',
]

export const GROUP_ELEMENT_TYPES = [
  { type: 'INFORMATION', subEls: ['TOOLTIP', 'BUTTON'] },
  { type: 'MPC', subEls: ['QUESTION', 'ANSWER'] },
]

export const GROUP_PARENT_ELEMENT_TYPES = ['QUESTION', 'TOOLTIP']
export const GROUP_CHILD_ELEMENT_TYPES = ['ANSWER', 'BUTTON']

export const VIDEO_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TOOLTIP',
]

export const getLetterFromNumber = (number) => {
  let result = ''
  while (number > 0) {
    let remainder = (number - 1) % 26
    result = String.fromCharCode(65 + remainder) + result
    number = Math.floor((number - 1) / 26)
  }
  return result
}

export const getPointClass = (points, scoringSystem) => {
  const classes = [
    'stable-dark',
    'royal',
    scoringSystem === 'SUBTRACT' ? 'assertive' : 'energized',
  ]
  if (scoringSystem === 'DISABLED') return classes[0]
  if (points > 2) return 'balanced'

  return classes[points ?? 0]
}

export const getPointColor = (points, scoringSystem) => {
  const colors = [
    '#93969f',
    '#e8c60a',
    scoringSystem === 'SUBTRACT' ? '#e7461a' : '#70c8b8',
  ]
  if (scoringSystem === 'DISABLED') return colors[0]
  if (points > 2) return '#548ab9'
  return colors[points ?? 0]
}

export const getElementFromSourceHandle = (reactFlow, nodeId, handleId) =>
  reactFlow
    .getNode(nodeId)
    .data.elements.find((e) => e.id === handleId.split('-')[1])

export const getConnectedElementsToScene = (scenes, sceneId) => {
  return scenes
    .map((scene) => scene.elements)
    .flat()
    .filter(
      (el) =>
        el.linkToId === sceneId || el.randomizedLinkToIds?.includes(sceneId)
    )
}

export const getDisplayedNodeElements = (nodeData) => {
  let nextParentIndex = 0
  const allAnswerElements = nodeData.elements.filter(
    (el) => GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
  )

  let elements = nodeData.elements.filter(
    (el) => !allAnswerElements.find((aEl) => aEl.id === el.id)
  )

  return elements.map((el) => {
    const answerElements = allAnswerElements.filter(
      (a) => a.groupUuid === el.groupUuid
    )
    const elementHasLetter =
      INTERACTIVE_ELEMENT_TYPES.includes(el.kind) ||
      (el.groupUuid && answerElements.length)

    const parentIndex = nextParentIndex
    nextParentIndex +=
      (elementHasLetter ? 1 : 0) +
      (answerElements.length ? answerElements.length - 1 : 0)

    return {
      ...el,
      index: parentIndex,
      answerElements,
    }
  })
}

export const selectSidebarElement = (id) => {
  const sidebarCard = document.querySelector(`#${id}`)
  if (sidebarCard) sidebarCard.classList.add('active')
}

export const unSelectSidebarElements = () => {
  ;[
    ...document.querySelectorAll('.c-video__editor--elements--card.active'),
  ].forEach((el) => el.classList.remove('active'))
}
