import React, { useLayoutEffect, useState } from 'react'
import { default as S } from '../../utils/lang/en.json'

const UploadLabel = ({ item, showFileName = true }) => {
  const [isUploading, setIsUploading] = useState(false)
  useLayoutEffect(() => {
    setIsUploading(!!item.progress)
  }, [item.progress])

  if (isUploading)
    return (
      <>
        {showFileName ? <span className="mr-2">{item.name}</span> : ''}
        <label
          className="o-label--custom o-label--assertive text-bold text-white"
          style={{ minWidth: '144px' }}>
          {S.dataTypes.transform.state[item.state].toUpperCase()}{' '}
          <span className="float-right">{item.progress}%</span>
        </label>
      </>
    )
  return (
    <>
      {showFileName ? <span className="mr-2">{item.name}</span> : ''}
      <label className="o-label--custom o-label--stable-lighter text-bold text-stable-dark">
        {S.dataTypes.transform.state[item.state].toUpperCase()}
      </label>
    </>
  )
}

export default UploadLabel
