import React, { useContext } from 'react'
import { FaMapPin } from 'react-icons/fa'
import FilmingLocationsDropdown from './FilmingLocationsDropdown'
import { updateSceneFilmingMutation } from '../../../../apollo/query/scenes'
import { useMutation } from '@apollo/client'
import { handleApolloError } from '../../../../utils/errors'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import ManageLocationsModal from '../Filming/ManageLocationsModal'

const FilmingTab = ({ selectedNode }) => {
  const scene = selectedNode.data
  const { refetch } = useContext(ScenarioEditorContext)

  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const handleUpdateFilming = () => {
    updateSceneFilming({
      variables: {
        id: scene.id,
        cameraLocationId: scene.cameraLocation?.id,
        useSameVideoAsId: scene.useSameVideoAsId,
        filming: !scene.filming,
      },
    })
  }

  const handleDeleteLocation = (locationId) => {
    refetch()
  }

  const handleUpdateLocation = (location) => {
    refetch()
  }

  return (
    <div className="p-3">
      <div className="border-light border-radius text-normal">
        <header className="pl-2 pr-2 pt-1-5 pb-1-5 flex-container align-middle align-justify border-light-bottom">
          <h3 className="text-bold flex-container align-middle mb-0 text-normal">
            <FaMapPin className="mr-1" />
            Filming location
          </h3>
          <button
            className="o-button o-button--light o-button--auto-width o-button--auto-height text-bold text-normal"
            data-open="manage-locations-tab-modal">
            Manage all
          </button>
        </header>
        <div className="pl-2 pr-2 pb-1-5 pt-1-5 flex-container align-middle align-justify border-light-bottom">
          Does scene need filming?
          <div className="o-switch">
            <input
              type="checkbox"
              data-testid="ending-input"
              name="linkToId"
              checked={scene.filming || false}
              readOnly
              id={`filming-${scene.id}`}
              className="o-switch__input"
            />
            <label
              onClick={handleUpdateFilming}
              htmlFor={`filming-${scene.id}`}
              className="o-switch__paddle"></label>
          </div>
        </div>

        <div className="pl-2 pr-2 pb-1-5 pt-1-5 flex-container align-middle align-justify">
          <span>Location</span>
          <FilmingLocationsDropdown selectedNode={selectedNode} />
        </div>
      </div>

      <ManageLocationsModal
        appendTo="#flow-container"
        id="manage-locations-tab-modal"
        deleteModalId="delete-location-tab-modal"
        onDeleteLocation={handleDeleteLocation}
        onUpdateLocation={handleUpdateLocation}
      />
    </div>
  )
}
export default FilmingTab
