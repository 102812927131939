import React from 'react'
import withApollo from '../../hooks/withApollo'
import PasswordForm from '../../components/UI/Form/PasswordForm'
import logo from '../../../../images/logo.svg'

const renderButton = (valid, passwordsMatch) => (
  <button
    type="submit"
    className="o-button text-bold w-100 mt-2"
    disabled={!(valid.allChecksPassed && passwordsMatch)}
    data-testid="submit-button">
    Confirm
  </button>
)

const ResetPassword = ({ token }) => {
  return (
    <div className="pt-4 pb-4 c-form c-form__devise">
      <header>
        <div className="flex-container align-middle align-center w-100 mb-5 pb-5">
          <img src={logo} loading="lazy" width={65} />
          <h2 className="ml-1 text-bold mb-0 c-launcher__logo--text">
            Warp Studio
          </h2>
        </div>
      </header>
      <h1 className="text-bold mb-4 text-center">Reset your password</h1>
      <PasswordForm
        token={token}
        onCompleted={() => (window.location.href = '/')}
        button={renderButton}
      />
    </div>
  )
}

export default withApollo(ResetPassword)
