export const sortEndingSettings = (endingSettings) => {
  return endingSettings.sort((a, b) => b.stars - a.stars)
}

export const getScorePeriods = (scoringSystem, endingSettings, pointsPerStar) => {
  return endingSettings.map((endingSetting) => {
    if (scoringSystem.kind === 'SUBTRACT') {
      return (5 - endingSetting.stars) * Number(pointsPerStar)
    }
    return endingSetting.stars * Number(pointsPerStar)
  })
}
