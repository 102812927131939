import React, { useEffect, useState } from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle'
import GeneralTab from './Settings/GeneralTab'
import { TabContent, TabTitles } from '../../UI/Tabs/Tabs'
import ScoringTab from './Settings/ScoringTab'

const Settings = () => {
  const updateDocumentTitle = useDocumentTitle()
  const [activeTab, setActiveTab] = useState(0)

  const titles = ['General', 'Scoring']

  const content = [
    <GeneralTab key="general-tab" />,
    <ScoringTab key="scoring-tab" />,
  ]

  useEffect(() => {
    updateDocumentTitle()
  }, [])

  return (
    <div className="c-workarea">
      <div id="settings-table" className="grid-container fluid row">
        <div className="columns small-12 large-3 xxlarge-2 pl-0">
          <TabTitles
            titles={titles}
            activeTab={activeTab}
            selectHandler={setActiveTab}
          />
        </div>
        <div className="columns">
          <TabContent titles={titles} content={content} activeTab={activeTab} />
        </div>
      </div>
    </div>
  )
}
export default Settings
