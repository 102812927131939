import React, { useContext, useState } from 'react'
import EmptyParagraph from './EmptyParagraph'
import { gql, useQuery } from '@apollo/client'
import Loading from './Loading'
import ActivityContext from '../../utils/context/ActivityContext'
import { FaPlus } from 'react-icons/fa'
import { convertDuration } from '../../utils/format'

const getScenarioAttemptsQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
    $metric: AttemptsMetric!
  ) {
    attemptsMetrics(
      metric: $metric
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: SCENARIO) {
        id
        name
        value
      }
    }
  }
`
const TOTAL_LENGTH = 10
const INITAL_LENGTH = 5

const MostPlayedScenarios = ({ metric = 'COUNT', attemptsMetricsData }) => {
  const { apiVars } = useContext(ActivityContext)
  const [showMore, setShowMore] = useState(false)

  const { data: scenariosMetrics } = useQuery(getScenarioAttemptsQuery, {
    variables: { ...apiVars, metric },
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })

  let scenarios = scenariosMetrics?.attemptsMetrics?.grouped ?? []
  scenarios = scenarios.slice(0, TOTAL_LENGTH)

  return (
    <>
      <h2 className="text-bold mb-2">Most played scenarios</h2>
      <div className="border-light border-radius">
        <table className="hover unstriped mb-0">
          <thead>
            <tr className="text-stable-dark border-light-bottom">
              <th
                className="text-center pt-1-5 pb-1-5 text-normal"
                width="60px">
                #
              </th>
              <th className="text-normal">Scenario</th>
              <th className="text-normal text-right pr-2-5" colSpan={2}>
                {metric === 'DURATION' ? 'Playing time' : 'Attempts'}
              </th>
            </tr>
          </thead>
          <tbody>
            {scenarios?.map((scenario, index) => (
              <tr
                className={
                  index < scenarios.length - 1 ? 'border-light-bottom' : ''
                }
                key={scenario.id}
                hidden={!showMore && index > INITAL_LENGTH - 1}>
                <td className="text-center pt-2 pb-2 text-normal" width="60px">
                  {index + 1}
                </td>
                <td className="text-normal">{scenario.name}</td>
                <td className="text-normal text-right pr-2-5">
                  {/* <span className="text-left">
                    <MostPlayedOptionsDropdown
                      onViewSceneCallback={handleViewSceneCallback}
                      onViewAttemptsCallback={handleViewAttemptsCallback}
                      id={scenario.id}
                    />
                  </span> */}
                  <span
                    className="u-ib text-bold ml-1-5 text-right"
                    style={{ minWidth: '23px' }}>
                    {metric === 'DURATION'
                      ? convertDuration(scenario.value)
                      : scenario.value}
                  </span>
                  {/*<span className="u-ib ml-1-5" style={{ minWidth: '38px' }}>
                     {scenario.percent !== 0 && (
                      <span
                        className={`text-bold ${
                          scenario.percent > 0
                            ? 'text-success'
                            : 'text-assertive'
                        }`}>
                        {scenario.percent > 0 ? '+' : '-'}&nbsp;
                        {Math.abs(scenario.percent)}%
                      </span>
                    )}
                  </span> */}
                </td>
              </tr>
            ))}
            {scenarios?.length > INITAL_LENGTH && !showMore && (
              <tr>
                <td
                  className="cursor-pointer text-bold text-normal text-stable-dark pt-1-5 pb-1-5"
                  colSpan={3}
                  onClick={() => setShowMore(true)}>
                  <div className="flex-container align-middle align-center">
                    <FaPlus /> &nbsp; Show more
                  </div>
                </td>
              </tr>
            )}
            {scenarios?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <EmptyParagraph />
                </td>
              </tr>
            )}
            {!scenarios && (
              <tr>
                <td colSpan={3}>
                  <Loading />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MostPlayedScenarios
