import React from 'react'
import ButtonEntity from '../../../Editor/Video/Elements/partials/Button'
import { getRotation } from '../../../Editor/helpers/controls'
import useHasHotspot from '../../../FlowV2/hooks/useHasHotspot'

const Button = ({ id, element, state }) => {
  const hasHotspot = useHasHotspot()
  
  if (!element) return null
  let labelWidth = 2.6
  if (element.labelSize === 'WIDE') labelWidth *= 2
  
  const collisionWidth = labelWidth + 0.5

  const rotation = getRotation({
    x: element.anchorX || 0,
    y: element.anchorY || -10,
  })

  return (
    <a-entity
      hideable-element={`visible: ${state.showElements}`}
      rotation={`${rotation} 0`}>
      <a-collision-box id={id} width={collisionWidth} scroll-header-offset={hasHotspot ? 260 : 125}>
        <a-entity position="0 0.1 0.01">
          <ButtonEntity
            textValue={element.label || 'Button'}
            width={labelWidth}
            id="button"
          />
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default Button
