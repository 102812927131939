import React, { useContext, useRef, useState } from 'react'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import Modal from '../../../../UI/Modal'
import { FaMicroscope, FaPlus } from 'react-icons/fa'
import { dateFormat, sortOnKey } from '../../../../../utils/format'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import TestModalForm from './TestModalForm'
import useCreateLoginSession from '../../../../../hooks/useCreateLoginSession'

const MAX_HEIGHT = 65
const MAX_MORE_HEIGHT = 400

const Row = ({ hasBorder = true, children, className = '' }) => (
  <div
    className={`pt-1-5 pb-1-5 pl-2 pr-2 ${className} ${hasBorder ? 'border-light-top' : ''}`}>
    {children}
  </div>
)

const Header = ({ createdAt }) => (
  <h3 className="text-normal text-bold flex-container align-middle mb-0">
    <FaMicroscope className="mr-1" />
    Test from {dateFormat(createdAt, 'DD MMM YYYY, HH:mm', false)}
  </h3>
)

const TestModal = ({ cancelCallback }) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const [testScenario, setTestScenario] = useState(scenario.testScenario)
  const [showMoreDesc, setShowMoreDesc] = useState(false)
  const [heightOver, setHeightOver] = useState()
  const descRef = useRef(null)
  const [archivedScenarios, setArchivedScenarios] = useState(
    sortOnKey(
      scenario.archivedScenarios.filter(
        (archivedScenario) => archivedScenario.status === 'ARCHIVED_TEST'
      ),
      'createdAt',
      true
    )
  )

  const [currentUser] = useCurrentUser()
  const [editing, setEditing] = useState(false)
  const createLoginSession = useCreateLoginSession()
  const [loading, setLoading] = useState(false)

  const getScrollHeight = () => descRef.current?.scrollHeight

  const handleOpen = () => {
    setTimeout(() => {
      setHeightOver(getScrollHeight() > MAX_HEIGHT)
    }, 100)
  }

  /*const renderAttempts = (attemptsCount) => { 
    return attemptsCount === 1 ? '1 attempt' : `${attemptsCount} attempts`
  }*/

  const handlePlay = () => {
    setLoading(true)
    createLoginSession({
      variables: {
        scenarioId: testScenario.id,
      },
    }).then(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <Modal
        id="test-modal"
        appendTo="#scenario-modals"
        headerText="Test scenario"
        subtitle={
          <div>
            Test a scenario at any moment, with or without videos. Create a new
            test or directly play a previously generated test.{' '}
            <a
              href="#"
              className="text-underline text-stable-dark"
              target="_blank">
              More info
            </a>
          </div>
        }
        cancelHandler={cancelCallback}
        closeOnEscape
        onOpen={handleOpen}>
        <div className="pt-3 pb-3 text-normal">
          <button
            className="button hollow secondary flex-container align-middle text-bold mb-0"
            data-open="new-test-modal"
            onClick={() => setEditing(false)}>
            <FaPlus className="mr-1" />
            New test version
          </button>

          {testScenario && (
            <div className="mt-2 border-light border-radius">
              <Row hasBorder={false}>
                <div className="flex-container align-middle align-justify ">
                  <Header createdAt={testScenario.createdAt} />
                  <button
                    className={`o-button o-button--royal o-button--auto-width o-button--square-small text-normal text-bold pl-1 pr-1 ${loading ? 'o-button--loading' : ''}`}
                    onClick={handlePlay}
                    disabled={loading}>
                    {loading ? '' : 'Play now'}
                  </button>
                </div>
              </Row>

              <Row>
                <div className="flex-container align-middle align-justify">
                  <span>Scenario version</span>
                  <span>{testScenario.version}</span>
                </div>
                {/*<div className="flex-container align-middle align-justify mt-1-5">
                  <span>Training attempts</span>
                   <span>{renderAttempts(testScenario.attemptsCount)}</span> 
                </div>*/}
              </Row>

              <Row>
                <div className="text-bold">
                  What can be tested in this version?
                </div>
                <div
                  className="mt-1-5"
                  ref={descRef}
                  style={{
                    maxHeight: `${showMoreDesc ? MAX_MORE_HEIGHT : MAX_HEIGHT}px`,
                    overflow: 'hidden',
                    transition: 'all .2s',
                  }}>
                  {testScenario.message}
                </div>
                {heightOver && (
                  <button
                    className="cursor-pointer text-underline text-stable-dark mt-1 bg-transparent tracking-tighter"
                    onClick={() => setShowMoreDesc(!showMoreDesc)}>
                    {showMoreDesc ? 'Show less' : 'Show more'}
                  </button>
                )}
              </Row>

              <Row>
                <div className="flex-container align-middle align-justify">
                  <span className="text-bold">
                    {testScenario.testers.length} Test members
                  </span>
                  <button
                    className="button hollow secondary small text-bold text-normal mb-0"
                    data-open="new-test-modal"
                    onClick={() => setEditing(true)}>
                    Edit
                  </button>
                </div>
                <div className="pt-0-5">
                  {testScenario.testers.map((tester) => (
                    <div
                      key={tester.id}
                      className="mt-1-5 flex-container align-middle">
                      {tester.name}&nbsp;
                      <span className="text-stable-dark">
                        •&nbsp;{tester.email}
                      </span>
                      {tester.id === currentUser?.id && (
                        <label className="o-label o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                          YOU
                        </label>
                      )}
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          )}

          {archivedScenarios.map((sc) => (
            <div key={sc.id} className="mt-2 border-light border-radius">
              <Row
                hasBorder={false}
                className="flex-container align-middle align-justify">
                <Header createdAt={sc.createdAt} />
                {/* <span>{renderAttempts(sc.attemptsCount)}</span> */}
              </Row>
            </div>
          ))}
        </div>
      </Modal>
      <TestModalForm
        editing={editing}
        testScenario={testScenario}
        setTestScenario={setTestScenario}
        archivedScenarios={archivedScenarios}
        setArchivedScenarios={setArchivedScenarios}
      />
    </>
  )
}
export default TestModal
