import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

const SceneToNumberLabel = ({ number, className }) => {
  return (
    <span
      className={`o-label o-label--stable-dark text-white text-small cursor-pointer ${className}`}>
      <FaArrowRight />
      &nbsp;
      {number}
    </span>
  )
}

export default SceneToNumberLabel
