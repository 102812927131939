import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from '../../../UI/Modal'
import SortableContainer from '../../../UI/SortableHoc/SortableContainer'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { FaGripVertical, FaPlus, FaTrashAlt } from 'react-icons/fa'
import {
  addCameraLocationMutation,
  updateCameraLocationsOrderMutation,
} from '../../../../apollo/query/cameraLocations'
import { useMutation } from '@apollo/client'
import { handleApolloError } from '../../../../utils/errors'
import useUpdateCameraLocation from '../../hooks/useUpdateCameraLocation'
import DeleteCameraLocationModal from './DeleteCameraLocationModal'

const Row = ({ location, onDeleteClick, onUpdateLocation }) => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [hover, setHover] = useState(false)
  const [editing, setEditing] = useState(false)
  const { updateCameraLocation } = useUpdateCameraLocation()

  const [addCameraLocation] = useMutation(addCameraLocationMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      setEditing(false)
      refetch()
    },
  })

  const formRef = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const name = form.elements['name'].value
    const description = form.elements['description'].value
    if (location.id)
      return updateCameraLocation({
        onCompleted: ({ updateCameraLocation: { cameraLocation } }) => {
          setEditing(false)
          onUpdateLocation(cameraLocation)
        },
        variables: {
          id: location.id,
          name,
          description,
        },
      })

    addCameraLocation({
      variables: {
        scenarioId: scenario.id,
        name,
        description,
      },
    })
  }

  useEffect(() => {
    if (editing)
      setTimeout(() => {
        formRef.current.elements['name'].select()
      }, 100)
  }, [editing])

  useEffect(() => {
    if (!location.id) setEditing(true)
  }, [location.id])

  return (
    <div className="border-light border-radius mb-0-5 text-normal bg-light">
      <div
        className="flex-container align-middle"
        onMouseEnter={setHover}
        onMouseLeave={() => setHover(false)}>
        <div className="drag-handle flex-container align-middle pl-2 pt-1 pb-1 pr-1">
          <FaGripVertical className="text-stable-dark" />
        </div>
        <div className="flex-container align-middle align-justify pr-2 pt-1 pb-1 w-100">
          <div className="text-bold">{location.name}</div>
          <div
            className="flex-container align-middle"
            style={{ minHeight: '32px' }}>
            {hover ? (
              <>
                <button
                  className="hollow button secondary small o-button--auto-width mb-0 text-normal mr-0-5 text-bold"
                  onClick={setEditing}>
                  Edit
                </button>

                <button
                  className="hollow button active small o-button--auto-width mb-0 text-normal"
                  data-open="delete-location-modal"
                  onClick={(e) => onDeleteClick(e, location)}>
                  <FaTrashAlt className="text-assertive" />
                </button>
              </>
            ) : (
              <span className="text-stable-dark">
                {location.scenesCount}{' '}
                {location.scenesCount === 1 ? 'scene' : 'scenes'}{' '}
              </span>
            )}
          </div>
        </div>
      </div>
      {editing && (
        <>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              inset: 0,
              zIndex: 2,
              cursor: 'not-allowed',
              background: 'rgba(255, 255, 255, 0.4)',
            }}></div>
          <form
            ref={formRef}
            className="p-2 border-light-top mt-0"
            onSubmit={handleSubmit}
            style={{ position: 'relative', zIndex: 3 }}>
            <label>Name</label>
            <input
              type="text"
              name="name"
              defaultValue={location.name}
              required
            />
            <label>Description</label>
            <textarea
              name="description"
              defaultValue={location.description}
              placeholder="Add a description"
              rows={7}
            />

            <div className="flex-container align-right">
              <button
                type="button"
                className="hollow button secondary mr-1"
                onClick={(e) => {
                  setEditing(false)
                  if (!location.id) onDeleteClick(e, location)
                }}>
                Cancel
              </button>
              <button type="submit" className="primary active button">
                Confirm
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  )
}

const ManageLocationsModal = ({
  appendTo,
  onDeleteLocation,
  onUpdateLocation,
  id,
  deleteModalId,
}) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const [cameraLocations, setCameraLocations] = useState(
    scenario.cameraLocations
  )
  const [selectedLocation, setSelectedLocation] = useState()

  const [updateCameraLocationsOrder] = useMutation(
    updateCameraLocationsOrderMutation,
    {
      onError: handleApolloError,
    }
  )

  const handleOrderChange = (srcIndex, destIndex) => {
    setCameraLocations((prevCameraLocations) => {
      const newCameraLocations = [...prevCameraLocations]
      if (srcIndex !== destIndex) {
        const [sourceItem] = newCameraLocations.splice(srcIndex, 1)
        newCameraLocations.splice(destIndex, 0, sourceItem)
      }

      const newOrderIds = newCameraLocations.map((location) =>
        Number(location.id)
      )
      updateCameraLocationsOrder({
        variables: {
          id: scenario.id,
          cameraLocationIds: newOrderIds,
        },
      })

      return newCameraLocations
    })
  }

  const addNewLocation = () => {
    const newLocation = {
      id: null,
      name: 'New location',
      description: '',
      scenesCount: 0,
    }
    setCameraLocations([...cameraLocations, newLocation])
  }

  const onDeleteClick = (e, location) => {
    if (location.id) {
      return setSelectedLocation(location)
    }
    e.stopPropagation()
    const newLocations = cameraLocations.filter((loc) => loc.id)
    setCameraLocations(newLocations)
  }

  useEffect(() => {
    const locations = scenario.cameraLocations.map((location) => {
      const scenesCount = scenario.scenes.filter(
        (scene) => scene.cameraLocation?.id === location.id
      ).length
      return {
        ...location,
        scenesCount,
      }
    })

    setCameraLocations(locations)
  }, [scenario.cameraLocations])

  return (
    <Modal
      id={id}
      headerText="Manage locations"
      appendTo={appendTo}
      width={600}
      closeOnEscape>
      <div className="pt-3 pb-1" style={{ position: 'relative' }}>
        <SortableContainer
          dragHandle=".drag-handle"
          onOrderChange={handleOrderChange}>
          {cameraLocations.map((location) => (
            <Row
              key={location.id}
              location={location}
              onDeleteClick={onDeleteClick}
              onUpdateLocation={onUpdateLocation}
            />
          ))}
        </SortableContainer>
        <button
          className="hollow button secondary o-button--auto-width text-normal mt-0-5 flex-container align-middle"
          onClick={addNewLocation}>
          <FaPlus />
          <span className="text-bold ml-0-5">New location</span>
        </button>
      </div>

      <DeleteCameraLocationModal
        id="delete-location-modal"
        manageModalId={id}
        appendTo={appendTo}
        onClose={() => setSelectedLocation(null)}
        locationId={selectedLocation?.id}
        callback={onDeleteLocation}
      />
    </Modal>
  )
}

export default ManageLocationsModal
