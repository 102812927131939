import React, { useContext, useState } from 'react'
import {
  addErrorAlert,
  addSuccessAlert,
} from '../../../../../utils/helpers/alerts'
import { validateImageExtension } from '../../../../../utils/validation/validate'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { useMutation } from '@apollo/client'
import {
  updateScenarioImageMutation,
  updateScenarioMutation,
} from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'
import { DirectUpload } from '@rails/activestorage'
import { default as S } from '../../../../../utils/lang/en.json'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

const ScenarioSettingsForm = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [updating, setUpdating] = useState()
  const [previewImage, setPreviewImage] = useState(scenario.imageUrl)
  const [image, setImage] = useState(scenario.imageUrl)
  const [title, setTitle] = useState(scenario.name)

  const finishUpdating = () => {
    setUpdating(false)
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${scenario.name}' were saved succesfully`,
    })
    refetch()
  }

  const [updateScenario] = useMutation(updateScenarioMutation, {
    onError: handleApolloError,
    onCompleted: finishUpdating,
  })

  const [updateScenarioWithImage] = useMutation(updateScenarioImageMutation, {
    onError: handleApolloError,
    onCompleted: finishUpdating,
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const { target } = e
    const description = target.elements['description'].value
    setUpdating(true)

    if (image !== scenario.imageUrl) {
      const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
      upload.create((error, blob) => {
        if (error) throw new Error(error.message)

        updateScenarioWithImage({
          variables: {
            id: scenario.id,
            name: title,
            description,
            blobId: blob.signed_id || '',
          },
        })
      })
    } else {
      updateScenario({
        variables: {
          id: scenario.id,
          name: title,
          description,
        },
      })
    }
  }
  return (
    <div className="row border-bottom pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Scenario settings</h2>
        <p>
          Determine the scenario image, title and description. These can all be
          seen by trainees.
        </p>
      </div>
      <form
        onSubmit={handleFormSubmit}
        className="xlarge-6 medium-8 small-12 columns mt-0">
        <label className="mb-1">Image</label>
        <div
          style={{
            backgroundImage: `url("${previewImage}")`,
          }}
          className="o-modal__scenario--image border-light border-radius"></div>
        <button className="button wide secondary hollow text-bold o-file-input o-file-input--basic mt-1 mb-2">
          Change image
          <input
            className="o-files-drop"
            type="file"
            onChange={(event) => {
              const file = event.target.files[0]
              if (validateImageExtension(file)) {
                setPreviewImage(URL.createObjectURL(file))
                setImage(file)
              } else {
                addErrorAlert({
                  title: `Invalid image file type: ${file.type}`,
                  subtitle: 'Please upload a png or jpeg image',
                  timeout: 6000,
                })
              }
            }}
            accept=".png,.jpg"
          />
        </button>

        <label>Scenario title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label>Scenario description</label>
        <textarea
          name="description"
          rows="5"
          defaultValue={scenario.description}></textarea>

        <button
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal mb-0 ${updating ? 'o-button--loading' : ''}`}
          disabled={updating || !title}
          aria-hidden="true">
          Confirm
        </button>
      </form>
    </div>
  )
}
export default ScenarioSettingsForm
