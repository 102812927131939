import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import MediumTitle from './partials/MediumTitle'
import { getMediumQuery } from '../../../apollo/query/media'
import LoadingSpinner from '../../UI/LoadingSpinner'
import FileInformation from './FileInformation'
import SceneInformation from './SceneInformation'
import MediumVideoPlayer from './partials/MediumVideoPlayer'
import TrimVideoRow from './partials/TrimVideoRow'
import { MediumContext } from './MediumContext'
import MediumControlsRow from './partials/MediumControlsRow'
import Delay from '../../../utils/helpers/Delay'

const MediumModal = ({
  mediumId,
  mediumIds,
  setMediumId,
  unlinkCallback = () => {},
  linkCallback = () => {},
}) => {
  const {
    medium: [medium, setMedium],
    time: [, setTime],
    controls: [controls, setControls],
  } = useContext(MediumContext)

  const [blockNavigation, setBlockNavigation] = useState(false)
  const { loading, error, refetch } = useQuery(getMediumQuery, {
    fetchPolicy: 'network-only',
    variables: { id: mediumId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      const n = { ...d.media[0] }
      setMedium(n)
    },
  })

  const navigationHandler = (next) => {
    const currentIndex = mediumIds.findIndex((i) => i === mediumId)
    const nextIndex =
      (mediumIds.length + (currentIndex + (next ? 1 : -1))) % mediumIds.length
    setControls({ ...controls, isPlaying: false })
    setTime(0)
    setMediumId(mediumIds[nextIndex])
  }

  const navigationKeyHandler = (e) => {
    if (!blockNavigation) {
      if (e.key === 'ArrowLeft') navigationHandler(false)
      if (e.key === 'ArrowRight') navigationHandler(true)
    }
  }

  useEffect(() => {
    if (!mediumId) {
      setMedium(null)
      setTime(0)
      setControls({ ...controls, isPlaying: false })
    }
    document.addEventListener('keydown', navigationKeyHandler, true)
    return () => {
      document.removeEventListener('keydown', navigationKeyHandler, true)
    }
  }, [mediumId, blockNavigation])

  if (!mediumId || !medium) return null
  if (loading)
    return (
      <Delay timeout="1000">
        <LoadingSpinner dotsOnly />
      </Delay>
    )

  if (error) throw new Error(error.message, error)

  return (
    <div key={mediumId} data-testid="medium-modal" className="grid-y">
      <div className="">
        <MediumTitle setBlockNavigation={setBlockNavigation} />
      </div>
      <div
        data-testid="nav-button--back"
        className="o-modal__navigation left"
        onClick={() => navigationHandler(false)}>
        <span className="icon">
          <FaArrowLeft />
        </span>
      </div>
      <div
        data-testid="nav-button--next"
        className="o-modal__navigation right"
        onClick={() => navigationHandler(true)}>
        <span className="icon">
          <FaArrowRight />
        </span>
      </div>
      {/* main area */}
      <div className="grid-x">
        <div
          className="cell xlarge-8  o-modal__column--main"
          data-testid="main-column">
          <div className="cell small-12">
            <MediumVideoPlayer />
            {medium.state === 'COMPLETED' && (
              <>
                <MediumControlsRow />
                <TrimVideoRow />
              </>
            )}
          </div>
          <div className="cell small-12 hide-for-xxlarge">
            <SceneInformation
              refetch={refetch}
              unlinkCallback={unlinkCallback}
              linkCallback={linkCallback}
            />
            <div className="hide-for-xlarge">
              <FileInformation />
            </div>
          </div>
        </div>
        <div className="cell auto show-for-xlarge hide-for-xxlarge">
          <FileInformation />
        </div>
        <div className="cell shrink show-for-xxlarge xlarge-4 xlarge-order-3 xxlarge-order-2">
          <SceneInformation
            refetch={refetch}
            unlinkCallback={unlinkCallback}
            linkCallback={linkCallback}
          />
          <FileInformation />
        </div>
      </div>
    </div>
  )
}

export default MediumModal
