import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import {
  addGroupMutation,
  getGroupNamesQuery,
} from '../../../../../apollo/query/groups'
import { sortOnKey } from '../../../../../utils/format'
import SearchableDropdown from '../../../../UI/Menu/SearchableDropdown'
import { handleApolloError } from '../../../../../utils/errors'

const PublishGroupsList = ({ groupList, setGroupList, scenario }) => {
  const parsedData = (group) => {
    return {
      render: group.name,
      value: group.name,
      id: group.id,
      defaultSelected: !!group.publishedScenarios.find(
        (sc) => sc.id === (scenario.publishedScenario?.id ?? scenario.id)
      ),
      traineesCount: group.userIds?.length || 0,
    }
  }
  const [allGroups, setAllGroups] = useState([])
  const [show, setShow] = useState(false)
  const [searchKey, setSearchKey] = useState('')

  const [getGroups, { loading }] = useLazyQuery(getGroupNamesQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ groups }) => {
      const ids = groupList.map((group) => group.id)
      const groupsArray = groups.reduce((array, group) => {
        if (!group.automated && !ids.includes(group.id))
          array.push(parsedData(group))
        return array
      }, [])
      setAllGroups(sortOnKey(groupsArray, 'render', false))
      const selectedGroups = groups.filter((group) =>
        group.publishedScenarios.find(
          (sc) => sc.id === (scenario.publishedScenario?.id || scenario.id)
        )
      )
      setGroupList(selectedGroups.map((group) => parsedData(group)))
    },
  })

  const [addNewGroup] = useMutation(addGroupMutation, {
    onError: handleApolloError,
    onCompleted: ({ addGroup: { group } }) => {
      group = parsedData(group)
      addGroup(group)
      setAllGroups(sortOnKey([...allGroups, group], 'render', false))
      setShow(false)
      setSearchKey('')
    },
  })

  const handleReset = () =>
    setGroupList(allGroups.filter((g) => g.defaultSelected))

  const removeGroup = (id) => {
    const newGroupList = groupList.filter((group) => group.id !== id)
    setGroupList(newGroupList)
  }

  const addGroup = (group) => {
    setGroupList([...groupList, group])
  }

  const showDetailGroupList = () => {
    if (!groupList.length)
      return (
        <div className="border-light border-radius flex-container align-middle pt-1-5 pb-1-5 pl-2 pr-2">
          <p className="text-italic mb-0">No groups selected yet...</p>
        </div>
      )
    return (
      <ul className="o-modal__video--list">
        {groupList.map((group) => {
          return (
            <li key={group.id} style={{ padding: '0 20px' }}>
              <div
                style={{
                  flexGrow: '1',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}>
                <span style={{ flexGrow: '1' }}> {group.render}</span>
                <div className="flex-container align-middle text-stable-dark">
                  {group.traineesCount && (
                    <span className="mr-1">
                      {group.traineesCount}{' '}
                      {group.traineesCount > 1 ? 'trainees' : 'trainee'}
                    </span>
                  )}
                  <span
                    className="cursor-pointer"
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                    onClick={() => removeGroup(group.id)}>
                    <FiX style={{ marginLeft: '10px' }} size="16" />
                  </span>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  const footer = (
    <div
      className="flex-container align-middle padding-vertical-1 text-stable-dark"
      onClick={() =>
        addNewGroup({
          variables: {
            name: searchKey,
            publishedScenarioIds: [],
            courseIds: [],
          },
        })
      }>
      <FaPlus className="mr-1" />
      <div>
        <span className="text-bold">{'Create a new group – '}</span>
        <span className="text-dark">{searchKey}</span>
      </div>
    </div>
  )

  useEffect(() => {
    getGroups()
  }, [scenario.id])

  return (
    <div>
      <div className="flex-container align-middle align-justify">
        <label>Add scenario to groups</label>
        <button
          type="button"
          onClick={handleReset}
          className="text-underline text-normal cursor-pointer mb-1 tracking-tighter o-button o-button--light border-0 p-0 o-button--auto-height o-button--auto-width">
          Reset
        </button>
      </div>

      {showDetailGroupList()}
      <div className="mt-1 mb-1">
        <button
          className="button hollow secondary text-bold mb-1 mt1"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShow(true)
          }}>
          <FaPlus className="mr-1" /> Select group
        </button>
        {show && (
          <SearchableDropdown
            placeholder="Search or create a group"
            clickHandler={(group) => {
              addGroup(group)
              setShow(false)
            }}
            hideHandler={() => setShow(false)}
            loading={loading}
            notFoundTitle="No groups found"
            notFoundText={
              <small>
                {`You don't have any groups to add or you have linked this
                  user to all groups`}
              </small>
            }
            data={allGroups}
            onChangeSearchKey={setSearchKey}
            footer={searchKey && footer}
          />
        )}
      </div>
    </div>
  )
}
export default PublishGroupsList
