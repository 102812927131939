import React from 'react'
import colors from '../../../../Editor/helpers/colors'
import useSelectedNode from '../../../../FlowV2/hooks/useSelectedNode'
import { getRotation } from '../../../../Editor/helpers/controls'

const NorthOffsetLine = () => {
  const selectedNode = useSelectedNode()
  const sceneState = selectedNode.data

  console.log('sceneState', sceneState.northOffset)

  return (
    <a-entity
      rotation={`${getRotation({ x: sceneState.northOffset, y: 0 })} 0`}>
      <a-entity
        id="northOffsetLine"
        position={`0 0 -49`}
        geometry="primitive: plane; height: 1000; width:0.4;"
        material={`color: ${colors.assertive}; opacity: 0.5`}
      />
    </a-entity>
  )
}
export default NorthOffsetLine
