import React, { Suspense, useContext, useEffect, useState } from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useMutation, useQuery } from '@apollo/client'
import {
  getCameraScenesQuery,
  updateSceneFilmingMutation,
  updateScenesFilmingOrderMutation,
} from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import EmptyPage from '../../EmptyPage'
import {
  FaChevronDown,
  FaHome,
  FaInfoCircle,
  FaQuestionCircle,
  FaVrCardboard,
} from 'react-icons/fa'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'
import TableHeader from '../../UI/TableHeader'
import Toast from '../../UI/Notifications/Toast'
// import TableSearchBar from '../../UI/Table/TableSearchBar'
import Table from '../../Table'
import DataTooltip from '../../UI/DataTooltip'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import Filter from '../../UI/Menu/Filter'
import ChangeFilmingGroupModal from '../components/Filming/ChangeFilmingGroupModal'
import { updateScenarioFilmingMutation } from '../../../apollo/query/scenarios'
import ManageLocationsModal from '../components/Filming/ManageLocationsModal'
import HomeLabel from '../components/FlowEditor/elements/HomeLabel'

const ExportPage = React.lazy(
  () => import('../components/Filming/Export/ExportPage')
)

const tableHeaders = [
  'number',
  'name',
  {
    name: 'locationName',
    render: (
      <div className="flex-container align-middle">
        Filming location{' '}
        <button
          className="button secondary hollow small text-bold mb-0 ml-1"
          data-open="manage-locations-modal">
          Manage
        </button>
      </div>
    ),
  },
  'filming',
]
const tableColumnWidths = [
  '80px',
  'calc(60% - 80px)',
  'calc(40% - 80px)',
  '80px',
]

const Filming = () => {
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const updateDocumentTitle = useDocumentTitle()
  // const [showEmptyFilters, setShowEmptyFilters] = useState(false)
  const [showEmptyPage, setShowEmptyPage] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [dismissToast, setDismissToast] = useState(false)
  // const [searchQuery, setSearchQuery] = useState('')
  // const [showEmptyState, setShowEmptyState] = useState(false)
  const [showExportPage, setShowExportPage] = useState(false)
  const [filterOption, setFilterOption] = useState({
    location: '',
    filming: '',
    groupBy: scenario.filmingGrouping || 'NONE',
  })
  const {
    data: cameraScenesData,
    loading,
    error,
    refetch: refetchCameraScenes,
  } = useQuery(getCameraScenesQuery, {
    variables: { id: scenario.id },
    onError: handleApolloError,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
  })
  const [scenesInFilmingOrder, setScenesInFilmingOrder] = useState()

  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      refetch()
      refetchCameraScenes().then(({ data: cameraScenesData }) => {
        checkAndReorder(cameraScenesData?.scenarios[0]?.scenesInFilmingOrder)
      })
    },
  })

  const [updateScenesFilmingOrder] = useMutation(
    updateScenesFilmingOrderMutation,
    {
      onError: handleApolloError,
    }
  )

  const [_updateScenarioFilming] = useMutation(updateScenarioFilmingMutation, {
    onError: handleApolloError,
  })

  const groupScenesByLocation = (data, parsed = true) => {
    const groupedData = data.reduce((acc, scene) => {
      const locationName = parsed
        ? scene.locationName?.value
        : scene.cameraLocation?.name
      const location = locationName ?? 'Missing camera location'
      if (!acc[location]) {
        acc[location] = {
          data: [],
          title: <></>,
        }
      }
      acc[location].data.push(scene)
      acc[location].title = (
        <div className="flex-container align-middle text-stable-dark text-normal">
          <span
            className={`o-label o-label--filming-group ${locationName ? '' : 'o-label--missing-location'}`}>
            {location}
          </span>
          <span className="ml-1 mr-1">•</span>
          {acc[location].data.length > 0 && (
            <>
              {acc[location].data.length}{' '}
              {acc[location].data.length > 1 ? 'scenes' : 'scene'}
            </>
          )}
        </div>
      )
      return acc
    }, {})
    return groupedData
  }

  const checkAndReorder = (scenesInFilmingOrder) => {
    if (!scenesInFilmingOrder) return false
    if (filterOption.groupBy === 'NONE') return false
    const flatScenesOrder = scenesInFilmingOrder.map((scene) => scene.id)
    const groupData = groupScenesByLocation(scenesInFilmingOrder, false)

    const groupedScenesOrder = Object.keys(groupData)
      .map((key) => groupData[key]?.data)
      .filter((group) => group)
      .flat()

    const groupedScenesOrderIds = groupedScenesOrder.map((scene) => scene.id)
    if (flatScenesOrder.join(',') !== groupedScenesOrderIds.join(',')) {
      setScenesInFilmingOrder(groupedScenesOrder)

      updateScenesFilmingOrder({
        variables: {
          id: scenario.id,
          sceneIds: groupedScenesOrderIds,
        },
      })
    }
  }

  useEffect(() => {
    if (cameraScenesData && !scenesInFilmingOrder) {
      const newScenesInFilmingOrder =
        cameraScenesData?.scenarios[0]?.scenesInFilmingOrder
      setScenesInFilmingOrder(newScenesInFilmingOrder)
      checkAndReorder(newScenesInFilmingOrder)
    }
  }, [cameraScenesData])

  useEffect(() => {
    updateDocumentTitle()
  }, [])

  if (loading || !scenesInFilmingOrder) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const handleOrderChange = (srcIndex, destIndex) => {
    setScenesInFilmingOrder((prevScenesFilmingOrder) => {
      const newScenesFilmingOrder = [...prevScenesFilmingOrder]
      if (srcIndex !== destIndex) {
        const [sourceItem] = newScenesFilmingOrder.splice(srcIndex, 1)
        newScenesFilmingOrder.splice(destIndex, 0, sourceItem)
      }

      const srcScene = prevScenesFilmingOrder[srcIndex]
      const destScene =
        prevScenesFilmingOrder[destIndex - (srcIndex === destIndex ? 1 : 0)]

      if (srcScene.cameraLocation?.id !== destScene?.cameraLocation?.id) {
        // changing scene camera location when scene is moved to a different location
        newScenesFilmingOrder[destIndex] = {
          ...newScenesFilmingOrder[destIndex],
          cameraLocation: destScene.cameraLocation,
        }
        const scene = {
          id: srcScene.id,
          cameraLocationId: destScene.cameraLocation?.id,
          useSameVideoAsId: srcScene.useSameVideoAsId,
          filming: srcScene.filming,
        }
        updateSceneFilming({
          variables: scene,
        })

        const sceneIndex = newScenesFilmingOrder.findIndex(
          (s) => s.id === scene.id
        )

        newScenesFilmingOrder[sceneIndex] = {
          ...newScenesFilmingOrder[sceneIndex],
          ...scene,
        }
      }

      const newSceneOrderIds = newScenesFilmingOrder.map((scene) =>
        Number(scene.id)
      )
      updateScenesFilmingOrder({
        variables: {
          id: scenario.id,
          sceneIds: newSceneOrderIds,
        },
      })

      return newScenesFilmingOrder
    })
  }

  const handleUpdateFilming = (scene, variables) => {
    const newScenesFilmingOrder = [...scenesInFilmingOrder]
    const sceneIndex = newScenesFilmingOrder.findIndex((s) => s.id === scene.id)
    newScenesFilmingOrder[sceneIndex] = {
      ...newScenesFilmingOrder[sceneIndex],
      ...variables,
    }
    setScenesInFilmingOrder(newScenesFilmingOrder)

    updateSceneFilming({
      variables: {
        id: scene.id,
        filming: variables.filming ?? scene.filming,
        useSameVideoAsId: variables.useSameVideoAsId ?? scene.useSameVideoAsId,
        cameraLocationId:
          variables.cameraLocation?.id ?? scene.cameraLocation?.id,
      },
    })
  }

  const LocationName = ({ scene }) => {
    const [showDropdown, setShowDropdown] = useState()
    return (
      <div
        className="flex-container text-dark align-middle"
        onClick={() => setShowDropdown(!showDropdown)}
        onMouseLeave={() => setShowDropdown(false)}
        style={{ height: '50px' }}>
        <span className="pr-1 filming--search-button-container">
          <a
            className="flex-container align-middle filming--search-button text-dark-light pr-3 text-dark"
            style={{ justifyContent: 'space-between' }}
            href="#"
            onClick={(e) => e.preventDefault()}
            onFocus={(e) => e.target.classList.remove('blur')}
            onBlur={(e) => e.target.classList.add('blur')}>
            {scene.cameraLocation?.name ? (
              <>
                {scene.cameraLocation.name}{' '}
                {process.env.NODE_ENV === 'development' &&
                  ` => ${scene.cameraLocation.id}`}
                {scene.cameraLocation.description && (
                  <DataTooltip title={scene.cameraLocation.description}>
                    <FaInfoCircle className="text-stable ml-1" />
                  </DataTooltip>
                )}
              </>
            ) : (
              <span className="text-assertive">Missing location</span>
            )}
            <FaChevronDown className="ml-1" />
          </a>
          <SearchableDropdown
            placeholder="Search or create a filming location"
            clickHandler={(cameraLocation) => {
              handleUpdateFilming(scene, {
                cameraLocation,
              })
            }}
            notFoundTitle="No filming locations found"
            notFoundText={
              <small>You don't have any filming locations to add</small>
            }
            data={scenario.cameraLocations.map((c) => ({
              ...c,
              value: c.name,
              render: c.name,
            }))}
            show={!!showDropdown}
            position="right"
          />
        </span>
      </div>
    )
  }

  const sortedData = () => {
    const parsedData = scenesInFilmingOrder.reduce((a, scene) => {
      // if (
      //   (!filterOption.location.length ||
      //     filterOption.location === scene.cameraLocation?.name) &&
      //   (!filterOption.filming.length ||
      //     filterOption.filming === (scene.filming ? 'Yes' : 'No'))
      // ) {
      //   const searchValues = `${scene.name} ${scene.description}`.toLowerCase()
      //   if (searchValues.includes(searchQuery.toLowerCase())) {
      a.push({
        id: scene.id,
        number: {
          value: scene.number,
          render: (
            <div
              className="flex-container align-middle text-normal text-bold"
              style={{ width: '50px' }}>
              {scene.start && <HomeLabel className="mr-0-5" />}
              <span className="o-label o-label--stable-dark text-white text-small">
                {scene.number}
              </span>
            </div>
          ),
        },
        name: {
          value: scene.name,
          render: (
            <div className="flex-container align-middle text-bold text-dark">
              {scene.name}
              {process.env.NODE_ENV === 'development' && ` => ${scene.id}`}
              {scene.description && (
                <DataTooltip title={scene.description}>
                  <FaInfoCircle className="text-stable ml-1" />
                </DataTooltip>
              )}
            </div>
          ),
        },
        locationName: {
          value: scene.cameraLocation?.name,
          render: <LocationName scene={scene} />,
        },
        filming: {
          value: scene.filming,
          render: (
            <div className="o-switch">
              <input
                type="checkbox"
                checked={scene.filming || false}
                readOnly
                id={`filming-${scene.id}`}
                className="o-switch__input"
              />
              <label
                onClick={() =>
                  handleUpdateFilming(scene, { filming: !scene.filming })
                }
                htmlFor={`filming-${scene.id}`}
                className="o-switch__paddle"></label>
            </div>
          ),
        },
      })
      // }
      // }
      return a
    }, [])

    if (filterOption.groupBy === 'LOCATION')
      return groupScenesByLocation(parsedData)

    return parsedData
  }

  const updateScenarioFilming = (filmingGrouping) => {
    _updateScenarioFilming({
      variables: {
        id: scenario.id,
        filmingGrouping,
      },
    })
  }

  const handleDeleteLocation = (locationId) => {
    const newScenesFilmingOrder = [...scenesInFilmingOrder]
    const updatedScenes = newScenesFilmingOrder.map((scene) => {
      if (scene.cameraLocation?.id === locationId) {
        return {
          ...scene,
          cameraLocation: null,
        }
      }
      return scene
    })
    setScenesInFilmingOrder(updatedScenes)

    refetch()
  }

  const handleUpdateLocation = (location) => {
    const newScenesFilmingOrder = [...scenesInFilmingOrder]
    const updatedScenes = newScenesFilmingOrder.map((scene) => {
      if (scene.cameraLocation?.id === location.id) {
        return {
          ...scene,
          cameraLocation: location,
        }
      }
      return scene
    })
    setScenesInFilmingOrder(updatedScenes)
    refetch()
  }

  const loadModals = () => {
    const appendTo = '#filming'

    return (
      <>
        <ChangeFilmingGroupModal
          appendTo={appendTo}
          onConfirm={() => {
            setFilterOption({ ...filterOption, groupBy: 'LOCATION' })
            updateScenarioFilming('LOCATION')
          }}
        />
        <ManageLocationsModal
          id="manage-locations-modal"
          appendTo={appendTo}
          deleteModalId="delete-location-modal"
          onDeleteLocation={handleDeleteLocation}
          onUpdateLocation={handleUpdateLocation}
        />
      </>
    )
  }

  /*const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    return setSelectedItems(() => newSelection)
  }*/

  const pageContent = () => {
    if (showEmptyPage)
      return <EmptyPage type="scenes" icon={<FaVrCardboard />} />
    return (
      <Table
        data={sortedData()}
        sortOn={{}}
        groupedBy={
          filterOption.groupBy !== 'NONE'
            ? filterOption.groupBy === 'LOCATION'
            : null
        }
        // sortHandler={(value) => sortHandler(value, preferences, setPreferences)}
        sortHandler={() => {}}
        headers={tableHeaders}
        columnWidths={tableColumnWidths}
        // selectedItems={selectedItems}
        // selectable
        // checkboxHandler={checkboxHandler}
        // selectAllHandler={(ids) => setSelectedItems(ids)}
        type="scenes"
        draggableRows
        onOrderChange={handleOrderChange}
      />
    )
  }

  // const tableSearchBar = (
  //   <>
  //     <TableSearchBar
  //       searchQuery={searchQuery}
  //       setSearchQuery={setSearchQuery}
  //       showEmptyState={showEmptyState}
  //       setShowEmptyState={setShowEmptyState}
  //     />
  //     <span className="cell shrink large-order-2 mr-1">
  //       <Filter
  //         text="Location"
  //         searchable
  //         icon={<FaChevronDown />}
  //         selected={
  //           filterOption.location.length ? filterOption.location : 'All'
  //         }
  //         filterHandler={(filter) => {
  //           if (showEmptyFilters) setShowEmptyFilters(false)
  //           return setFilterOption(
  //             filter
  //               ? { ...filterOption, location: filter }
  //               : { ...filterOption, location: '' }
  //           )
  //         }}
  //         filterOptions={[
  //           'All',
  //           ...new Set(
  //             scenario.scenes
  //               .filter((d) => d.cameraLocation)
  //               .map((c) => c.cameraLocation.name)
  //           ),
  //         ]}
  //       />
  //     </span>
  //     <span className="cell shrink large-order-2 mr-1">
  //       <Filter
  //         text="Need filming"
  //         searchable
  //         icon={<FaChevronDown />}
  //         selected={filterOption.filming.length ? filterOption.filming : 'All'}
  //         filterHandler={(filter) => {
  //           if (showEmptyFilters) setShowEmptyFilters(false)
  //           return setFilterOption(
  //             filter
  //               ? { ...filterOption, filming: filter }
  //               : { ...filterOption, filming: '' }
  //           )
  //         }}
  //         filterOptions={['All', 'Yes', 'No']}
  //       />
  //     </span>
  //   </>
  // )

  const tableRightBar = (
    <div className="flex-container" style={{ position: 'relative' }}>
      <Filter
        text="Group by"
        icon={<FaChevronDown />}
        selected={filterOption.groupBy}
        filterHandler={(filter) => {
          if (filter === filterOption.groupBy) return false
          if (filter === 'NONE') {
            setFilterOption({ ...filterOption, groupBy: filter })
            updateScenarioFilming(filter)
          } else $('#change-filming-group-modal').foundation('open')
        }}
        filterOptions={[
          { render: 'None', value: 'NONE' },
          { render: 'Location', value: 'LOCATION' },
        ]}
      />
      <button
        className="button secondary hollow text-bold ml-1"
        onClick={setShowExportPage}>
        Export
      </button>
    </div>
  )

  return (
    <>
      <div
        id="filming"
        className="c-container c-container--vertical no-print"
        style={{ minHeight: 'calc(100vh - 122px)' }}>
        <div className="c-workarea" style={{ position: 'relative' }}>
          {loadModals()}
          <div className="grid-container fluid">
            <TableHeader
              title="Filming schedule"
              // leftBar={tableSearchBar}
              rightBar={tableRightBar}
            />
          </div>
          <div className="o-table--page-container grid-container fluid">
            {pageContent()}
          </div>
          <Toast
            dismissable
            isClosing={dismissToast}
            count={selectedItems.length}
            itemTypeText={selectedItems.length > 1 ? 'scenes' : 'scene'}
            closeHandler={() => {
              setDismissToast(true)
              setTimeout(() => setDismissToast(false), 300)
              setTimeout(() => setSelectedItems([]), 10)
            }}
          />
        </div>
      </div>
      <Suspense>
        {showExportPage && (
          <ExportPage onCancel={() => setShowExportPage(false)} />
        )}
      </Suspense>
    </>
  )
}
export default Filming
